import '../fontawesome'
import 'slick-carousel'
import intlTelInput from 'intl-tel-input'
import 'pwstrength-bootstrap/dist/pwstrength-bootstrap'
import swal from 'sweetalert2'
import WebFont from 'webfontloader'
import Turbolinks from 'turbolinks'
import ekkoLightbox from 'ekko-lightbox'
import select2 from 'select2'
import Plyr from 'plyr'

/**
 * JS Settings App
 */
let jsonSettings = document.querySelector(
  '[data-settings-selector="settings-json"]'
)
window.settings = jsonSettings ? JSON.parse(jsonSettings.textContent) : {}

window.swal = swal
window.locale = $('html').attr('lang')

export default createApp => {
  Turbolinks.start()

  /**
   * Font
   */
  WebFont.load({
    google: {
      families: ['Roboto']
    }
  })

  /**
   * Cookie Consent
   */
  window.addEventListener('load', () => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#fff',
          text: '#777'
        },
        button: {
          background: '#3097d1',
          text: '#ffffff'
        }
      },
      showLink: false,
      theme: 'edgeless',
      content: {
        message: window.settings.cookieconsent.message,
        dismiss: window.settings.cookieconsent.dismiss
      }
    })
  })

  $(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault()

    if (window.ekkoLightbox) {
      $.fn['ekkoLightbox'] = window.ekkoLightbox
      $(this).ekkoLightbox({
        alwaysShowClose: true
      })
    } else {
      window.ekkoLightbox = $.fn['ekkoLightbox']
      $(this).ekkoLightbox({
        alwaysShowClose: true
      })
    }
  })

  document.addEventListener('turbolinks:load', () => {
    /**
     * Vue Mounting
     */
    if (document.getElementById('app') !== null) {
      const { app } = createApp()
      app.$mount('#app')
    }

    /**
     * Tel Input
     */
    ;[...document.querySelectorAll('input[type="tel"]')].forEach(input => {
      intlTelInput(input)
    })

    /**
     * Bind all bootstrap tooltips
     */
    $('[data-toggle="tooltip"]').tooltip()

    /**
     * Bind all bootstrap popovers
     */
    $('[data-toggle="popover"]').popover()

    /**
     * smooth scroll anchor with hash
     */
    $('a.scroll').click(function() {
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $($(this).attr('href')).offset().top - 70
          },
          400
        )
    })

    /**
     * smooth scroll to top
     */
    function showHideBackToTop() {
      if (
        document.body.scrollTop > 120 ||
        document.documentElement.scrollTop > 120
      ) {
        $('.scrollTop').addClass('visible')
      } else {
        $('.scrollTop').removeClass('visible')
      }
    }

    $('a.scrollTop').click(function() {
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: 0
          },
          400
        )
      return false
    })

    /**
     * Slick
     */
    $('#home-slider')
      .not('.slick-initialized')
      .slick({
        autoplay: true,
        dots: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 7000,
        fade: true,
        arrows: false,
        cssEase: 'linear'
      })

    $('.project-filter .dropdown-menu a').click(function() {
      let name = $(this).data('name')
      let value = $(this).data('value')
      let text = $(this).text()
      $("input[name='" + name + "']").val(value)
      $(this)
        .parents('.dropdown')
        .find('.dropdown-toggle')
        .html(text + ' <span class="caret"></span>')
      // $('#search').submit()
    })

    function submitSearchForm(oFormElement) {
      var xhr = new XMLHttpRequest()
      xhr.open(oFormElement.method, oFormElement.getAttribute('action'), true)
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
      xhr.send(new FormData(oFormElement))
      return false
    }

    /**
     * Bind all swal confirm buttons
     */
    $('[data-toggle="confirm"]').click(e => {
      e.preventDefault()

      window
        .swal({
          title: $(e.currentTarget).attr('data-trans-title'),
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: $(e.currentTarget).attr('data-trans-button-cancel'),
          confirmButtonColor: '#dd4b39',
          confirmButtonText: $(e.currentTarget).attr(
            'data-trans-button-confirm'
          )
        })
        .then(result => {
          if (result.value) {
            $(e.target)
              .closest('form')
              .submit()
          }
        })
    })

    $('[data-toggle="password-strength-meter"]').pwstrength({
      ui: {
        bootstrap4: true
      }
    })

    /**
     * Bootstrap tabs nav specific hash manager
     */
    let hash = document.location.hash
    let tabanchor = $('.nav-tabs a:first')

    if (hash) {
      tabanchor = $(`.nav-tabs a[href="${hash}"]`)
    }

    tabanchor.tab('show')

    $('a[data-toggle="tab"]').on('show.bs.tab', e => {
      window.location.hash = e.target.hash
    })

    $('.project-slider-main').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.project-slider-thmb'
    })
    $('.project-slider-thmb').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.project-slider-main',
      dots: true,
      centerMode: true,
      focusOnSelect: true
    })
    $('#v-project-tabs a').on('click', function(e) {
      e.preventDefault()
      $('#project-tabs a').removeClass('show active')
      $(this).tab('show')
    })
    $('#project-tabs a').on('click', function(e) {
      e.preventDefault()
      $('#v-project-tabs a').removeClass('show active')
      $(this).tab('show')
    })
    function isEmpty(el) {
      return !$.trim(el.html())
    }
    function getAjaxData(loadurl, targ, type = '') {
      $.get(loadurl, function(data) {
        $(targ).html(data)
        if (type === 'video') {
          const players = Plyr.setup('.js-player')
        }
      })
    }
    $('[data-toggle="tabajax"]').click(function(e) {
      e.preventDefault()

      let loadurl = $(this).attr('href')
      let targ = $(this).attr('data-target')
      let type = $(this).attr('data-type')
      if (isEmpty($(targ))) {
        getAjaxData(loadurl, targ, type)
      }
      $(this).tab('show')
    })
    $(document).on('click', '.tabajax a.page-link', function(event) {
      event.preventDefault()
      let loadurl = $(this).attr('href')
      let targ = $('.tabajax').attr('data-target')
      getAjaxData(loadurl, targ)
      $(targ + '-tab').tab('show')
    })

    window.onscroll = function() {
      growShrinkLogo()
      showHideBackToTop()
    }

    function growShrinkLogo() {
      var Logo = document.getElementById('rf-logo')
      if (
        document.body.scrollTop > 180 ||
        document.documentElement.scrollTop > 180
      ) {
        Logo.classList.add('logo-shrink')
        Logo.src = '/images/rf_logo_BW.png'
      } else {
        Logo.classList.remove('logo-shrink')
        Logo.src = '/images/rf_logo.png'
      }
    }

    const player = Plyr.setup('.js-player')
    window.player = player

    /**
     * RainViewer script starts
     */
    if ($('#mapid').length) {
      var map = L.map('mapid', {
        zoomControl: false,
        scrollWheelZoom: false
      }).setView([23.777176, 90.399452], 7)

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attributions:
          'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
      }).addTo(map)

      /**
       * RainViewer radar animation part
       * @type {number[]}
       */
      var timestamps = []
      var radarLayers = []

      var animationPosition = 0
      var animationTimer = false

      /**
       * Load actual radar animation frames timestamps from RainViewer API
       */
      var apiRequest = new XMLHttpRequest()
      apiRequest.open(
        'GET',
        'https://api.rainviewer.com/public/maps.json',
        true
      )
      apiRequest.onload = function(e) {
        // save available timestamps and show the latest frame: "-1" means "timestamp.lenght - 1"
        timestamps = JSON.parse(apiRequest.response)
        showFrame(-1)
      }
      apiRequest.send()

      /**
       * Animation functions
       * @param ts
       */
      function addLayer(ts) {
        if (!radarLayers[ts]) {
          radarLayers[ts] = new L.TileLayer(
            'https://tilecache.rainviewer.com/v2/radar/' +
              ts +
              '/256/{z}/{x}/{y}/2/1_1.png',
            {
              tileSize: 256,
              opacity: 0.001,
              zIndex: ts
            }
          )
        }
        if (!map.hasLayer(radarLayers[ts])) {
          map.addLayer(radarLayers[ts])
        }
      }

      /**
       * Display particular frame of animation for the @position
       * If preloadOnly parameter is set to true, the frame layer only adds for the tiles preloading purpose
       * @param position
       * @param preloadOnly
       */
      function changeRadarPosition(position, preloadOnly) {
        while (position >= timestamps.length) {
          position -= timestamps.length
        }
        while (position < 0) {
          position += timestamps.length
        }

        var currentTimestamp = timestamps[animationPosition]
        var nextTimestamp = timestamps[position]

        addLayer(nextTimestamp)

        if (preloadOnly) {
          return
        }

        animationPosition = position

        if (radarLayers[currentTimestamp]) {
          radarLayers[currentTimestamp].setOpacity(0)
        }
        radarLayers[nextTimestamp].setOpacity(100)

        document.getElementById('timestamp').innerHTML = new Date(
          nextTimestamp * 1000
        ).toString()
      }

      /**
       * Check avialability and show particular frame position from the timestamps list
       */
      function showFrame(nextPosition) {
        var preloadingDirection = nextPosition - animationPosition > 0 ? 1 : -1

        changeRadarPosition(nextPosition)

        // preload next next frame (typically, +1 frame)
        // if don't do that, the animation will be blinking at the first loop
        changeRadarPosition(nextPosition + preloadingDirection, true)
      }

      /**
       * Stop the animation
       * Check if the animation timeout is set and clear it.
       */
      function stop() {
        if (animationTimer) {
          clearTimeout(animationTimer)
          animationTimer = false
          return true
        }
        return false
      }

      function play() {
        showFrame(animationPosition + 1)

        // Main animation driver. Run this function every 500 ms
        animationTimer = setTimeout(play, 500)
      }

      function playStop() {
        if (!stop()) {
          play()
        }
      }

      $(document).on('click', '.prev-frame', function(event) {
        event.preventDefault()
        stop()
        showFrame(animationPosition - 1)
      })

      $(document).on('click', '.next-frame', function(event) {
        event.preventDefault()
        stop()
        showFrame(animationPosition + 1)
      })

      $(document).on('click', '.play-frame', function(event) {
        event.preventDefault()
        playStop()
      })
    }
    // rain map scrip end

    // RWH Calculator script start
    if ($('#rwh_calc').length) {
      $(document).on('click', '.catchmentType', function(event) {
        let catchmentTypeId = $(this).val()
        $('.catchmentType_img').attr(
          'src',
          '/images/catchment/catchment' + catchmentTypeId + '.jpg'
        )
        getRainFall()
      })
      $(document).on(
        'click',
        '.area_unit, .rainFallUnit, .rfDuration, .calculate',
        function(event) {
          getRainFall()
        }
      )
      $(document).on('click', '.locationRadio', function(event) {
        let locationRadioCity = $(this).attr('id')
        $('#locationRadio_img')
          .removeClass()
          .addClass('location ' + locationRadioCity)

        getRainFall()
      })
      var getAreaFunction = function() {
        let length = document.getElementById('length')
        let width = document.getElementById('width')
        let area = document.getElementById('area')

        area.value = length.value * width.value
        let event = new Event('change')
        area.dispatchEvent(event)
        area.addEventListener('change', getRainFall)
      }

      var getRainFall = function() {
        let f = $('input[name=type]:checked', '#rwh_calc').data('avg')

        let a = Math.round(document.getElementById('area').value)
        if ($('input[name=area_unit]:checked', '#rwh_calc').val() == 'feet') {
          a = Math.round(a * 0.092903)
        }

        let rainData = $('input[name=location]:checked', '#rwh_calc')
          .data('store')
          .split(',')

        let r = rainData[12]

        let amount_RW = Math.round(a * f * r)

        let tanks = Math.round(amount_RW / 10000) // 10000 litre tank as unit

        let rfDuration = $('input[name=duration]:checked', '#rwh_calc').val()

        let rfUnit = $('input[name=unit]:checked', '#rwh_calc').val()

        if (rfUnit == 'cubic_meter') {
          amount_RW = Math.round(amount_RW * 0.001)
        }
        if (rfUnit == 'gallon') {
          amount_RW = Math.round(amount_RW * 0.2641721)
        }

        if (rfDuration == 'monthly') {
          let rfData = []
          let unit = 1
          for (let i = 0; i < rainData.length; i++) {
            if (rfUnit == 'cubic_meter') {
              unit = 0.001
            }
            if (rfUnit == 'gallon') {
              unit = 0.2641721
            }
            let monthlyData = Math.round(a * f * rainData[i] * unit)
            rfData.push(monthlyData)
          }
          rainData = rfData
        }
        showRainResult(rfUnit, rfDuration, tanks, rainData, amount_RW)
      }
      var showRainResult = function(
        rfUnit,
        rfDuration,
        tanks,
        rainData,
        amount_RW
      ) {
        let name = rfUnit.replace('_', ' ')
        let rfUnitName = name
          .toLowerCase()
          .replace(/\b[a-z]/g, function(letter) {
            return letter.toUpperCase()
          })

        $('#rfH').html('Result: Amount of Rain Water in ' + rfUnitName)

        $('#str_result').html(
          'Result: Amount of Rain Water = ' + amount_RW + ' ' + rfUnitName
        )

        let monthly_rf = ''
        for (let i = 0; i < rainData.length; i++) {
          monthly_rf += '<td class="text-nowrap">' + rainData[i] + '</td>'
        }

        $('#monthly_rf').html(monthly_rf)

        if (rfDuration == 'monthly') {
          $('#arr_result').show('fast')
          $('#str_result').hide('fast')
        } else {
          $('#arr_result').hide('fast')
          $('#str_result').show('fast')
        }

        $('#tank_quantity').html(
          '<strong>' + tanks + '</strong> tank(10000 litre) water annually'
        )
        let tankQnt = ''
        for (let j = 0; j < tanks; j++) {
          if (j > 45) {
            tankQnt += '<strong> . . . . . </strong>'
            break
          }
          tankQnt += '<img src="/images/tank_sm.png" width="60" />'
        }

        $('#tank_quantity_img').html(tankQnt)
      }
      $(document).ready(function() {
        let length = document.getElementById('length')
        let width = document.getElementById('width')
        length.addEventListener('change', getAreaFunction)
        width.addEventListener('change', getAreaFunction)
      })
    }
    // RWH Calculator script end

    $(document).ready(function() {
      $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
        var $el = $(this)
        var $parent = $(this).offsetParent('.dropdown-menu')
        if (
          !$(this)
            .next()
            .hasClass('show')
        ) {
          $(this)
            .parents('.dropdown-menu')
            .first()
            .find('.show')
            .removeClass('show')
        }
        var $subMenu = $(this).next('.dropdown-menu')
        $subMenu.toggleClass('show')

        $(this)
          .parent('li')
          .toggleClass('show')

        $(this)
          .parents('li.nav-item.dropdown.show')
          .on('hidden.bs.dropdown', function(e) {
            $('.dropdown-menu .show').removeClass('show')
          })

        if (!$parent.parent().hasClass('navbar-nav')) {
          $el
            .next()
            .css({ top: $el[0].offsetTop, left: $parent.outerWidth() - 4 })
        }

        return false
      })
    })
  })
}
