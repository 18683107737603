import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faHome,
  faGlobe,
  faUser,
  faUsers,
  faSearch,
  faArrowRight,
  faTint,
  faThumbsUp,
  faFlag,
  faExclamationTriangle,
  faPencilAlt,
  faStar as fasStar
} from '@fortawesome/free-solid-svg-icons'

import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'

import {
  faFacebook,
  faTwitter,
  faGoogle,
  faLinkedin,
  faGithub,
  faBitbucket,
  faPinterest
} from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faHome,
  faGlobe,
  faUser,
  faUsers,
  faSearch,
  faFacebook,
  faTwitter,
  faGoogle,
  faLinkedin,
  faGithub,
  faBitbucket,
  faPinterest,
  faArrowRight,
  faTint,
  faThumbsUp,
  faFlag,
  faExclamationTriangle,
  faPencilAlt,
  fasStar,
  farStar
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
