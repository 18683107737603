export default {
    "bn": {
        "alerts": {
            "backend": {
                "users": {
                    "created": "ইউজার তৈরি হয়েছে",
                    "updated": "ইউজার আপডেট হয়েছে",
                    "deleted": "ইউজার ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত ইউজার ডিলিট হয়েছে",
                    "bulk_enabled": "নির্বাচিত ইউজার সক্রিয় হয়েছে",
                    "bulk_disabled": "নির্বাচিত ইউজার নিষ্ক্রিয় হয়েছে"
                },
                "roles": {
                    "created": "রোল তৈরি হয়েছে",
                    "updated": "রোল আপডেট হয়েছে",
                    "deleted": "রোল ডিলিট হয়েছে"
                },
                "metas": {
                    "created": "মেটা তৈরি হয়েছে",
                    "updated": "মেটা আপডেট হয়েছে",
                    "deleted": "মেটা ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত মেটা ডিলিট হয়েছে"
                },
                "form_submissions": {
                    "deleted": "সাবমিশন ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত সাবমিশন ডিলিট হয়েছে"
                },
                "form_settings": {
                    "created": "ফর্ম সেটিং তৈরি হয়েছে",
                    "updated": "ফর্ম সেটিং আপডেট হয়েছে",
                    "deleted": "ফর্ম সেটিং ডিলিট হয়েছে"
                },
                "redirections": {
                    "created": "রিডিরেক্শন তৈরি হয়েছে",
                    "updated": "রিডিরেক্শন আপডেট হয়েছে",
                    "deleted": "রিডিরেক্শন ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত রিডিরেক্শন ডিলিট হয়েছে",
                    "bulk_enabled": "নির্বাচিত রিডিরেক্শন সক্রিয় হয়েছে",
                    "bulk_disabled": "নির্বাচিত রিডিরেক্শন নিষ্ক্রিয় হয়েছে",
                    "file_imported": "ফাইল সফলভাবে ইম্পোর্ট করা হয়েছে"
                },
                "categories": {
                    "created": "ক্যাটাগরি তৈরি হয়েছে",
                    "updated": "ক্যাটাগরি আপডেট হয়েছে",
                    "deleted": "ক্যাটাগরি ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত ক্যাটাগরি ডিলিট হয়েছে",
                    "bulk_active": "নির্বাচিত ক্যাটাগরি একটিভ হয়েছে"
                },
                "menus": {
                    "created": "মেনু তৈরি হয়েছে",
                    "updated": "মেনু আপডেট হয়েছে",
                    "deleted": "মেনু ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত মেনু ডিলিট হয়েছে",
                    "bulk_active": "নির্বাচিত মেনু একটিভ হয়েছে"
                },
                "galleries": {
                    "created": "গ্যালারি তৈরি হয়েছে",
                    "updated": "গ্যালারি আপডেট হয়েছে",
                    "deleted": "গ্যালারি ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত গ্যালারি ডিলিট হয়েছে",
                    "bulk_active": "নির্বাচিত গ্যালারি একটিভ হয়েছে"
                },
                "posts": {
                    "created": "পোস্ট তৈরি হয়েছে",
                    "updated": "পোস্ট আপডেট হয়েছে",
                    "deleted": "পোস্ট ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত পোস্ট ডিলিট হয়েছে",
                    "bulk_published": "নির্বাচিত পোস্ট প্রকাশিত হয়েছে",
                    "bulk_pending": "নির্বাচিত পোস্ট গুলো পরিমার্জনা প্রত্যাশী",
                    "bulk_pinned": "নির্বাচিত পোস্ট পিন করা হয়েছে",
                    "bulk_promoted": "নির্বাচিত পোস্ট উন্নীত করা হয়েছে"
                },
                "pages": {
                    "created": "পেজ তৈরি হয়েছে",
                    "updated": "পেজ আপডেট হয়েছে",
                    "deleted": "পেজ ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত পেজ ডিলিট হয়েছে",
                    "bulk_published": "নির্বাচিত পেজ প্রকাশিত হয়েছে",
                    "bulk_pending": "নির্বাচিত পেজ গুলো পরিমার্জনা প্রত্যাশী",
                    "bulk_pinned": "নির্বাচিত পেজ পিন করা হয়েছে",
                    "bulk_promoted": "নির্বাচিত পেজ উন্নীত করা হয়েছে"
                },
                "projects": {
                    "created": "প্রোজেক্ট তৈরি হয়েছে",
                    "updated": "প্রোজেক্ট আপডেট হয়েছে",
                    "deleted": "প্রোজেক্ট ডিলিট হয়েছে",
                    "bulk_destroyed": "নির্বাচিত প্রোজেক্ট ডিলিট হয়েছে",
                    "bulk_published": "নির্বাচিত প্রোজেক্ট প্রকাশিত হয়েছে",
                    "bulk_pending": "নির্বাচিত প্রোজেক্ট গুলো পরিমার্জনা প্রত্যাশী",
                    "bulk_pinned": "নির্বাচিত প্রোজেক্ট পিন করা হয়েছে",
                    "bulk_promoted": "নির্বাচিত প্রোজেক্ট উন্নীত করা হয়েছে"
                },
                "actions": {
                    "invalid": "অবৈধ প্রক্রিয়া"
                }
            },
            "frontend": {
                "user": {
                    "password_updated": "পাসওয়ার্ড সফলভাবে আপডেট হয়েছে। "
                }
            }
        },
        "auth": {
            "failed": "এই পরিচয়পত্রগুলি আমাদের রেকর্ডের সাথে মেলে না।",
            "throttle": "আপনি অনেক বেশি লগইন প্রচেষ্টা করেছেন।  {seconds} সেকেন্ড পর আবার চেষ্টা করুন।",
            "socialite": {
                "unacceptable": "{provider} লগইন টাইপ গ্রহণযোগ্য নয়।"
            }
        },
        "buttons": {
            "cancel": "বাতিল",
            "save": "সংরক্ষণ",
            "close": "বদ্ধ",
            "create": "সৃষ্টি",
            "delete": "ডিলিট",
            "confirm": "নিশ্চিত",
            "show": "প্রদর্শন",
            "manage": "পরিচালনা",
            "edit": "সম্পাদনা",
            "update": "হালনাগাদ",
            "view": "দর্শন",
            "preview": "পূর্বরূপ",
            "back": "পেছনে",
            "send": "প্রেরণ",
            "login-as": "{name} হিসাবে লগইন করুন",
            "apply": "আবেদন",
            "post": "পোস্ট",
            "submit": "সাবমিট",
            "ask_questions": "প্রশ্ন জিজ্ঞাসা করুন",
            "comments": "মন্তব্য",
            "add": "যোগ",
            "remove": "অপসারণ",
            "users": {
                "create": "ব্যবহারকারী তৈরি করুন"
            },
            "roles": {
                "create": "রোল তৈরি করুন"
            },
            "metas": {
                "create": "মেটা তৈরি করুন"
            },
            "form_settings": {
                "create": "সেটিং তৈরি করুন"
            },
            "redirections": {
                "create": "পুনঃনির্দেশগুলি তৈরি করুন",
                "import": "CSV আমদানি করুন"
            },
            "menus": {
                "main": "মেনু",
                "create": "মেনু তৈরি করুন"
            },
            "galleries": {
                "main": "গ্যালারি",
                "create": "গ্যালারি তৈরি করুন"
            },
            "categories": {
                "main": "ক্যাটাগরি",
                "create": "ক্যাটাগরি তৈরি করুন"
            },
            "posts": {
                "create": "পোস্ট তৈরি করুন",
                "save_and_publish": "সংরক্ষণ করুন এবং প্রকাশ করুন",
                "save_as_draft": "খসড়া হিসেবে সংরক্ষণ করুন"
            },
            "pages": {
                "create": "পেজ তৈরি করুন",
                "save_and_publish": "সংরক্ষণ করুন এবং প্রকাশ করুন",
                "save_as_draft": "খসড়া হিসেবে সংরক্ষণ করুন"
            },
            "projects": {
                "create": "প্রজেক্ট তৈরি করুন",
                "save_and_publish": "সংরক্ষণ করুন এবং প্রকাশ করুন",
                "save_as_draft": "খসড়া হিসেবে সংরক্ষণ করুন"
            },
            "financial_progresses": {
                "main": "আর্থিক অগ্রগতি",
                "create": "আর্থিক অগ্রগতি তৈরি করুন"
            },
            "physical_progresses": {
                "main": "শারীরিক অগ্রগতি",
                "create": "শারীরিক অগ্রগতি তৈরি করুন"
            },
            "project_images": {
                "main": "প্রজেক্ট চিত্র",
                "create": "প্রজেক্ট চিত্র তৈরি করুন"
            },
            "project_videos": {
                "main": "প্রজেক্ট ভিডিও",
                "create": "প্রজেক্ট ভিডিও তৈরি করুন"
            },
            "project_documents": {
                "main": "প্রজেক্ট ডকুমেন্ট",
                "create": "প্রজেক্ট ডকুমেন্ট তৈরি করুন"
            },
            "project_inspections": {
                "main": "প্রজেক্ট পরিদর্শন",
                "create": "প্রজেক্ট পরিদর্শন তৈরি করুন"
            },
            "project_teams": {
                "main": "প্রকল্প দল",
                "create": "প্রকল্প দল তৈরি করুন"
            },
            "project_links": {
                "main": "প্রজেক্ট লিংক",
                "create": "প্রজেক্ট লিংক তৈরি করুন"
            },
            "project_challenges": {
                "main": "প্রজেক্ট চ্যালেঞ্জ",
                "create": "প্রজেক্ট চ্যালেঞ্জ তৈরি করুন"
            },
            "project_obstructions": {
                "main": "প্রজেক্ট প্রতিবন্ধকতা",
                "create": "প্রজেক্ট প্রতিবন্ধকতা তৈরি করুন"
            },
            "project_meetings": {
                "main": "প্রকল্প সভা",
                "create": "প্রকল্প সভা তৈরি করুন"
            },
            "project_variations": {
                "main": "প্রজেক্ট বৈচিত্র",
                "create": "প্রজেক্ট বৈচিত্র তৈরি করুন"
            },
            "project_reports": {
                "main": "প্রকল্প রিপোর্ট",
                "create": "প্রকল্প রিপোর্ট তৈরি করুন"
            }
        },
        "exceptions": {
            "general": "সার্ভার বর্জন",
            "unauthorized": "ক্রিয়াকলাপ অনুমোদিত নয়",
            "backend": {
                "users": {
                    "create": "ব্যবহারকারী তৈরিতে ত্রুটি",
                    "update": "ব্যবহারকারী হালনাগাতে ত্রুটি",
                    "delete": "ব্যবহারকারী ডিলিটে ত্রুটি",
                    "first_user_cannot_be_edited": "আপনি সুপার অ্যাডমিনকে সম্পাদনা করতে পারবেন না",
                    "first_user_cannot_be_disabled": "সুপার অ্যাডমিনকে অক্ষম করা যাবে না",
                    "first_user_cannot_be_destroyed": "সুপার অ্যাডমিনকে ডিলিট করা যাবে না",
                    "first_user_cannot_be_impersonated": "সুপার অ্যাডমিনকে রুপায়িত করা যাবে না",
                    "cannot_set_superior_roles": "আপনি নিজের থেকে রোলকে সেরা হিসাবে চিহ্নিত করতে পারবেন না"
                },
                "roles": {
                    "create": "রোল তৈরিতে ত্রুটি",
                    "update": "রোল হালনাগাদে ত্রুটি",
                    "delete": "রোল ডিলিটে ত্রুটি"
                },
                "metas": {
                    "create": "মেটা তৈরিতে ত্রুটি",
                    "update": "মেটা হালনাগাদে ত্রুটি",
                    "delete": "মেটা ডিলিটে ত্রুটি",
                    "already_exist": "এই লোকাল রুটের জন্য ইতিমধ্যে একটি মেটা রয়েছে"
                },
                "menus": {
                    "create": "মেনু তৈরিতে ত্রুটি",
                    "update": "মেনু হালনাগাদে ত্রুটি",
                    "delete": "মেনু ডিলিটে ত্রুটি"
                },
                "form_submissions": {
                    "create": "জমা দেওয়ার ক্ষেত্রে ত্রুটি",
                    "delete": "জমা ডিলিটের ক্ষেত্রে ত্রুটি"
                },
                "form_settings": {
                    "create": "ফর্ম তৈরির ক্ষেত্রে ত্রুটি",
                    "update": "ফর্ম হালনাগাদের ক্ষেত্রে ত্রুটি",
                    "delete": "ফর্ম সেটিংস ডিলিটের ক্ষেত্রে ত্রুটি",
                    "already_exist": "এই ফর্মটির সাথে ইতিমধ্যে একটি সেটিংস লিঙ্ক রয়েছে"
                },
                "redirections": {
                    "create": "পুনঃনির্দেশ তৈরির সময় ত্রুটি",
                    "update": "পুনঃনির্দেশ হালনাগাদের সময় ত্রুটি",
                    "delete": "পুনঃনির্দেশ ডিলিটের সময় ত্রুটি",
                    "already_exist": "ইতিমধ্যে এই পথটির জন্য পুনঃনির্দেশ রয়েছে"
                },
                "posts": {
                    "create": "পোস্ট তৈরির সময় ত্রুটি",
                    "update": "পোস্ট হালনাগাদের সময় ত্রুটি",
                    "save": "পোস্ট জমানোর সময় ত্রুটি",
                    "delete": "পোস্ট ডিলিটের সময় ত্রুটি"
                },
                "pages": {
                    "create": "পেজ তৈরির সময় ত্রুটি",
                    "update": "পেজ হালনাগাদের সময় ত্রুটি",
                    "save": "পেজ জমানোর সময় ত্রুটি",
                    "delete": "পেজ ডিলিটের সময় ত্রুটি"
                },
                "projects": {
                    "create": "প্রজেক্ট তৈরিতে ত্রুটি",
                    "update": "প্রজেক্ট হালনাগাদে ত্রুটি",
                    "save": "প্রজেক্ট জমাতে ত্রুটি",
                    "delete": "প্রজেক্ট ডিলিটে ত্রুটি"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "ই-মেইল ঠিকানা ইতিমধ্যে নেওয়া হয়েছে।",
                    "password_mismatch": "এটি আপনার পুরানো পাসওয়ার্ড নয়।",
                    "delete_account": "অ্যাকাউন্ট ডিলিটের ক্ষেত্রে ত্রুটি।",
                    "updating_disabled": "অ্যাকাউন্ট সম্পাদনা অক্ষম।"
                },
                "auth": {
                    "registration_disabled": "নিবন্ধন বন্ধ।"
                }
            }
        },
        "forms": {
            "contact": {
                "display_name": "যোগাযোগের ফর্ম"
            }
        },
        "labels": {
            "language": "ভাষা",
            "actions": "কর্ম",
            "general": "বিশ্বজনীন",
            "no_results": "কোন ফলাফল নেই",
            "search": "অনুসন্ধান",
            "validate": "যাচাই",
            "choose_file": "ফাইল পছন্দ কর",
            "no_file_chosen": "কোনো ফাইল পছন্দ করা হইনি",
            "are_you_sure": "আপনি কি নিশ্চিত ?",
            "delete_image": "চিত্র ডিলিট করুন",
            "delete_report": "রিপোর্ট ডিলিট করুন",
            "yes": "হ্যাঁ",
            "no": "না",
            "add_new": "যুক্ত",
            "export": "রপ্তানি",
            "more_info": "আরও তথ্য",
            "author": "কর্তা",
            "author_id": "কর্তা আইডি",
            "last_access_at": "সর্বশেষে প্রবেশ",
            "published_at": "প্রকাশিত",
            "created_at": "নির্মিত হয়েছে",
            "updated_at": "হালনাগাদ হয়েছে",
            "deleted_at": "ডিলিট হয়েছে",
            "no_value": "কোন মান নেই",
            "upload_image": "চিত্র আপলোড",
            "anonymous": "বেনামী",
            "all_rights_reserved": "সর্বস্বত্ব সংরক্ষিত।",
            "with": "সঙ্গে",
            "by": "দ্বারা",
            "rhd_overview": "১৯৪৭ সনে ভারত ও পাকিস্তান বিভক্তির পর কেন্দ্রীয় পাকিস্তানের সকল নির্মাণ কার্যাদি সম্পাদনের ভার গণপূর্ত বিভাগের উপর ন্যাস্ত হয়। \r\n    যোগাযোগ ব্যবস্থা ও পূর্ত নির্মাণ(C & B) পূর্ব পাকিস্তানের সরকার দ্বারা পরিচালিত হয় পরবর্তীতে ১৯৬২ সনে (C & B) দুই ভাগে বিভক্ত হয়ে ১টি গণপূর্ত অধিদপ্তর ও \r\n    অন্যটি সড়ক ও জনপথ অধিদপ্তর হয়। বর্তমানে সড়ক ও জনপথ অধিদপ্তর কর্তৃক জাতীয়, আঞ্চলিক ও জেলা সড়ক সমূহ সহ গুরুত্বপূর্ণ ব্রিজ ও কালভার্ট উন্নয়ন ও \r\n    রক্ষনাবেক্ষন কার্যক্রম পরিচালিত হচ্ছে। সড়ক ও জনপথ অধিদপ্তরের আওতায় প্রায় ২২,০৯৬.৩০৩ কিঃমিঃ সড়ক, ৩,৫৪৮ টি ব্রিজ, ৮৫৬ টি বেইলী ব্রিজ , ১৪,৮১৪ টি কালভার্ট রয়েছে।",
            "datatables": {
                "no_results": "কোন ফলাফল নেই",
                "no_matched_results": "সমকক্ষ কোন ফলাফল নেই",
                "show_per_page": "প্রদর্শনী",
                "entries_per_page": "প্রতি পৃষ্ঠায় এন্ট্রি",
                "search": "অনুসন্ধান",
                "infos": "{total} এন্ট্রি এর  {offset_start} থেকে {offset_end} দেখাচ্ছে"
            },
            "morphs": {
                "post": "পোস্ট, পরিচয় {id}",
                "project": "প্রজেক্ট, পরিচয় {id}",
                "user": "ব্যবহারকারী, পরিচয় {id}"
            },
            "auth": {
                "disabled": "আপনার অ্যাকাউন্ট নিষ্ক্রিয় করা হয়েছে।"
            },
            "http": {
                "403": {
                    "title": "এক্সেস ডিনাইড",
                    "description": "দুঃখিত, আপনার এই পৃষ্ঠাটি অ্যাক্সেস করার অনুমতি নেই।"
                },
                "404": {
                    "title": "পৃষ্ঠা খুঁজে পাওয়া যায়নি",
                    "description": "দুঃখিত, আপনি যে পৃষ্ঠাটি দেখার চেষ্টা করছেন তা বিদ্যমান নেই।"
                },
                "500": {
                    "title": "সার্ভার সমস্যা",
                    "description": "দুঃখিত, সার্ভার এমন একটি পরিস্থিতির মুখোমুখি হয়েছে যে এটি পরিচালনা করতে জানে না। আমরা যত তাড়াতাড়ি সম্ভব এটি ঠিক করব।"
                }
            },
            "localization": {
                "en": "ইংরেজি",
                "bn": "বাংলা"
            },
            "placeholders": {
                "route": "একটি বৈধ অভ্যন্তরীণ রুট নির্বাচন করুন",
                "tags": "একটি ট্যাগ নির্বাচন করুন বা তৈরি করুন",
                "type": "প্রজেক্টের ধরন নির্বাচন করুন",
                "chart_type": "চার্ট নমুনা নির্বাচন করুন",
                "zone": "একটি জোন নির্বাচন করুন",
                "circle": "একটি সার্কেল নির্বাচন করুন",
                "status": "একটি স্ট্যাটাস নির্বাচন করুন",
                "team_description": "দলের বিবরণ",
                "team_lead": "প্রধান পরিদর্শক",
                "team_members": "দলের সদস্যরা",
                "project_belongs_to": "প্রজেক্টের জন্য নির্বাচন করুন।",
                "meeting_lead": "চেয়ার দ্বারা",
                "meeting_members": "অংশগ্রহণকারী সদস্য",
                "position": "অবস্থান",
                "superior": "উচ্চতর",
                "order": "ক্রম",
                "parent_category": "মূল বিভাগ",
                "category": "বিভাগ",
                "parent_menu": "জনক মেনু"
            },
            "cookieconsent": {
                "message": "আপনি যাতে আমাদের ওয়েবসাইটে সেরা অভিজ্ঞতা পান তা নিশ্চিত করতে এই ওয়েবসাইটটি কুকিজ ব্যবহার করে।",
                "dismiss": "বুঝেছি !"
            },
            "descriptions": {
                "allowed_image_types": "অনুমোদিত টাইপ: png gif jpg jpeg.",
                "allowed_document_types": "অনুমোদিত টাইপ: pdf.",
                "allowed_video_types": "অনুমোদিত টাইপ: mp4, mov, wmv.",
                "allowed_report_types": "অনুমোদিত টাইপ: pdf."
            },
            "user": {
                "dashboard": "ড্যাশবোর্ড",
                "remember": "মনে রাখা",
                "login": "লগ ইন",
                "logout": "লগ আউট",
                "password_forgot": "পাসওয়ার্ড ভুলে গেছেন ?",
                "send_password_link": "রিসেট পাসওয়ার্ড লিঙ্ক প্রেরণ করুন",
                "password_reset": "পাসওয়ার্ড পুনরায় সেট করুন",
                "register": "রেজিষ্টার",
                "register_invite": "কোন অ্যাকাউন্ট নেই?",
                "login_invite": "ইতিমধ্যে একটি অ্যাকাউন্ট আছে?",
                "space": "আমার স্থান",
                "settings": "সেটিংস",
                "account": "আমার অ্যাকাউন্ট",
                "profile": "আমার প্রোফাইল",
                "avatar": "আভাটার",
                "online": "অনলাইন",
                "edit_profile": "আমার প্রোফাইল সম্পাদনা",
                "change_password": "আমার পাসওয়ার্ড পরিবর্তন",
                "delete": "আমার একাউন্ট ডিলিট",
                "administration": "প্রশাসন",
                "member_since": "মেম্বার  {date} হইতে",
                "profile_updated": "প্রোফাইল সফলভাবে আপডেট হয়েছে।",
                "password_updated": "পাসওয়ার্ড সফলভাবে আপডেট হয়েছে।",
                "super_admin": "সুপার প্রশাসক",
                "account_delete": "<p>এই ক্রিয়াটি পুরোপুরি আপনার অ্যাকাউন্টটিকে এই সাইট থেকে সমস্ত সম্পর্কিত ডেটা মুছে ফেলবে।</p>",
                "account_deleted": "অ্যাকাউন্ট সফলভাবে ডিলিট হয়েছে",
                "titles": {
                    "space": "আমার স্থান",
                    "account": "আমার অ্যাকাউন্ট"
                }
            },
            "alerts": {
                "login_as": "আপনি প্রকৃতপক্ষে  <strong>{name}</strong> হিসাবে লগ ইন করেছেন , আপনি <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a> হিসাবে লগআউট করতে পারেন।",
                "no_data_registered": "এখনও কোন রেকর্ড নিবন্ধিত হয়নি!"
            },
            "backend": {
                "dashboard": {
                    "new_posts": "নতুন পোস্ট",
                    "pending_posts": "অমিমাংশিত পোস্ট",
                    "published_posts": "প্রকাশিত পোস্ট",
                    "active_users": "সক্রিয় ব্যবহারকারীগণ",
                    "form_submissions": "জমা",
                    "last_posts": "শেষ পোস্ট",
                    "last_published_posts": "শেষ প্রকাশনা",
                    "last_pending_posts": "শেষ অমিমাংশিত পোস্ট",
                    "last_new_posts": "সর্বশেষ নতুন পোস্ট",
                    "all_posts": "সমস্ত পোস্ট",
                    "new_projects": "নতুন প্রজেক্ট",
                    "pending_projects": "অমিমাংশিত প্রজেক্ট",
                    "published_projects": "প্রকাশিত প্রজেক্ট",
                    "ongoing_projects": "চলমান প্রকল্প",
                    "complete_projects": "সম্পূর্ণ প্রকল্প",
                    "proposed_projects": "প্রস্তাবিত প্রকল্পসমূহ",
                    "last_projects": "শেষ প্রজেক্ট",
                    "last_published_projects": "শেষ প্রকাশনা",
                    "last_pending_projects": "শেষ অমিমাংশিত প্রজেক্টগুলি",
                    "last_new_projects": "সর্বশেষ নতুন প্রজেক্ট",
                    "all_projects": "সমস্ত প্রজেক্ট"
                },
                "new_menu": {
                    "project": "নতুন প্রজেক্ট",
                    "zone": "নতুন জোন",
                    "post": "নতুন পোস্ট",
                    "form_setting": "নতুন ফর্ম সেটিং",
                    "user": "নতুন ব্যবহারকারী",
                    "role": "নতুন রোল",
                    "meta": "নতুন মেটা",
                    "redirection": "নতুন পুনঃনির্দেশ"
                },
                "sidebar": {
                    "content": "কন্টেন্ট ম্যানেজমেন্ট",
                    "project": "প্রজেক্ট পরিচালনা",
                    "forms": "ফর্ম পরিচালনা",
                    "access": "অ্যাক্সেস ম্যানেজমেন্ট",
                    "seo": "SEO সেটিংস",
                    "setting": "সেটিংস",
                    "media": "মিডিয়া ম্যানেজমেন্ট"
                },
                "titles": {
                    "dashboard": "ড্যাশবোর্ড",
                    "generator_builder": "জেনারেটর নির্মাতা",
                    "log_viewer": "লগ ভিউয়ার"
                },
                "users": {
                    "titles": {
                        "main": "ব্যবহারকারী",
                        "index": "ব্যবহারকারীর তালিকা",
                        "create": "ব্যবহারকারী তৈরি",
                        "edit": "ব্যবহারকারী পরিবর্তন"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত ব্যবহারকারীদের ডিলিট করুন।",
                        "enable": "নির্বাচিত ব্যবহারকারীদের সক্ষম করুন",
                        "disable": "নির্বাচিত ব্যবহারকারীদের অক্ষম করুন"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "রোল",
                        "index": "রোল তালিকা",
                        "create": "রোল তৈরি",
                        "edit": "রোল পরিবর্তন"
                    }
                },
                "metas": {
                    "titles": {
                        "main": "মেটা",
                        "index": "মেটা তালিকা",
                        "create": "মেটা তৈরি",
                        "edit": "মেটা পরিবর্তন"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত মেটাগুলি ডিলিট করুন।"
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "জমা",
                        "index": "জমা দেওয়ার তালিকা",
                        "show": "জমা দেওয়ার বিশদ"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত জমাগুলি ডিলিট করুন।"
                    }
                },
                "form_settings": {
                    "titles": {
                        "main": "ফরম",
                        "index": "ফরম সেটিং তালিকা",
                        "create": "ফরম সেটিং তৈরি",
                        "edit": "ফরম সেটিং তৈরি"
                    },
                    "descriptions": {
                        "recipients": "উদাহরণ: 'webmaster@example.com' অথবা 'sales@example.com,support@example.com' . একাধিক প্রাপক নির্দিষ্ট করতে, প্রতিটি ইমেল ঠিকানাটি কমা দিয়ে আলাদা করুন।",
                        "message": "ফর্মটি জমা দেওয়ার পরে ব্যবহারকারীর কাছে এই বার্তা প্রদর্শিত হবে। কোনও বার্তা প্রদর্শিত না হলে ফাঁকা রাখুন।"
                    }
                },
                "redirections": {
                    "titles": {
                        "main": "রিডিরেকশন",
                        "index": "রিডিরেকশন তালিকা",
                        "create": "রিডিরেকশন তৈরি",
                        "edit": "রিডিরেকশন পরিবর্তন"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত রিডিরেকশনগুলি ডিলিট করুন।",
                        "enable": "নির্বাচিত রিডিরেকশনগুলি সক্ষম করুন",
                        "disable": "নির্বাচিত রিডিরেকশনগুলি অক্ষম করুন"
                    },
                    "types": {
                        "permanent": "স্থায়ী পুনঃনির্দেশ (301)",
                        "temporary": "অস্থায়ী পুনঃনির্দেশ (302)"
                    },
                    "import": {
                        "title": "CSV ফাইল আমদানি",
                        "label": "CSV ফাইল আমদানি করার জন্য নির্বাচন কর",
                        "description": "ফাইলটিতে \"উত্স\" এবং \"টার্গেট\" সহ শিরোনাম হিসাবে 2 টি কলাম থাকতে হবে, পুনর্নির্দেশটি ডিফল্টরূপে স্থায়ী হবে"
                    }
                },
                "menus": {
                    "titles": {
                        "main": "মেনু",
                        "index": "মেনু তালিকা",
                        "create": "মেনু সৃষ্টি",
                        "edit": "মেনু পরিবর্তন"
                    },
                    "statuses": {
                        "inactive": "নিষ্ক্রিয়",
                        "active": "সক্রিয়"
                    },
                    "categories": {
                        "mainmenu": "প্রধান সূচি",
                        "topmenu": "শীর্ষ মেনু",
                        "bottommenu": "নীচের মেনু",
                        "footermenu": "নীচের মেনু"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত মেনু মুছুন"
                    }
                },
                "categories": {
                    "titles": {
                        "main": "বিভাগ",
                        "index": "বিভাগ তালিকা",
                        "create": "বিভাগ তৈরি",
                        "edit": "বিভাগ পরিবর্তন"
                    },
                    "statuses": {
                        "inactive": "নিষ্ক্রিয়",
                        "active": "সক্রিয়"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত বিভাগগুলি মুছুন"
                    }
                },
                "galleries": {
                    "titles": {
                        "main": "গ্যাল্যারি",
                        "index": "গ্যালারী তালিকা",
                        "create": "গ্যালারী তৈরি",
                        "edit": "গ্যালারী পরিবর্তন"
                    },
                    "statuses": {
                        "inactive": "নিষ্ক্রিয়",
                        "active": "সক্রিয়"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত গ্যালারী মুছুন"
                    }
                },
                "posts": {
                    "statuses": {
                        "draft": "খসড়া",
                        "pending": "অমীমাংসিত",
                        "published": "প্রকাশিত"
                    },
                    "titles": {
                        "main": "পোস্ট",
                        "index": "পোস্ট তালিকা",
                        "create": "পোস্ট তৈরি করুন",
                        "edit": "পোস্ট সম্পাদন করুন",
                        "publication": "প্রকাশের বিকল্পগুলি"
                    },
                    "descriptions": {
                        "meta_title": "শিরোনামটি খালি রাখলে, ডিফল্ট হিসাবে নিবন্ধন হবে।",
                        "meta_description": "বিবরণটি খালি রাখলে, ডিফল্ট হিসাবে সংক্ষিপ্তসার নিবন্ধন হবে।"
                    },
                    "placeholders": {
                        "body": "আপনার বিষয়বস্তু লিখুন...",
                        "meta_title": "অনুচ্ছেদ শিরোনাম।",
                        "meta_description": "অনুচ্ছেদ সারাংশ।"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত পোস্ট ডিলিট করুন",
                        "publish": "নির্বাচিত পোস্ট প্রকাশ করুন",
                        "pin": "নির্বাচিত পোস্টগুলি পিন করুন",
                        "promote": "নির্বাচিত পোস্ট প্রচার করুন"
                    }
                },
                "pages": {
                    "statuses": {
                        "draft": "খসড়া",
                        "pending": "বিচারাধীন",
                        "published": "প্রকাশিত"
                    },
                    "titles": {
                        "main": "পেজ",
                        "index": "পৃষ্ঠা তালিকা",
                        "create": "পাতা তৈরি করুন",
                        "edit": "সম্পাদনা পাতা",
                        "publication": "প্রকাশের বিকল্পগুলি"
                    },
                    "descriptions": {
                        "meta_title": "খালি রেখে দিলে শিরোনামটি নিবন্ধের শিরোনাম হবে ",
                        "meta_description": "খালি রেখে দিলে ডিফল্টরূপে নিবন্ধের সংক্ষিপ্তসারটি বর্ণনা হবে।"
                    },
                    "placeholders": {
                        "body": "আপনার সামগ্রী লিখুন ...",
                        "meta_title": "নিবন্ধের শিরোনাম।",
                        "meta_description": "নিবন্ধের সংক্ষিপ্তসার।"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত পৃষ্ঠাগুলি মুছুন",
                        "publish": "নির্বাচিত পৃষ্ঠাগুলি প্রকাশ করুন",
                        "pin": "নির্বাচিত পৃষ্ঠাগুলি পিন করুন",
                        "promote": "নির্বাচিত পৃষ্ঠাগুলি প্রচার করুন"
                    }
                },
                "projects": {
                    "statuses": {
                        "unpublished": "অপ্রকাশিত",
                        "published": "প্রকাশিত"
                    },
                    "types": {
                        "proposed": "প্রস্তাবিত",
                        "ongoing": "অগ্রসরমান",
                        "complete": "সম্পূর্ণ"
                    },
                    "titles": {
                        "main": "প্রজেক্ট",
                        "index": "প্রজেক্ট তালিকা",
                        "create": "প্রজেক্ট তৈরি করুন",
                        "edit": "প্রজেক্ট সম্পাদন করুন",
                        "publication": "প্রকাশের বিকল্পগুলি"
                    },
                    "descriptions": {
                        "meta_title": "শিরোনামটি খালি রাখলে, ডিফল্ট হিসাবে নিবন্ধন হবে।",
                        "meta_description": "বিবরণটি খালি রাখলে, ডিফল্ট হিসাবে সংক্ষিপ্তসার নিবন্ধন হবে।"
                    },
                    "placeholders": {
                        "body": "আপনার বিষয়বস্তু লিখুন...",
                        "meta_title": "অনুচ্ছেদ শিরোনাম।",
                        "meta_description": "অনুচ্ছেদ সারাংশ।"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত প্রজেক্ট ডিলিট করুন",
                        "publish": "নির্বাচিত প্রজেক্ট প্রকাশ করুন",
                        "pin": "নির্বাচিত প্রজেক্টগুলি পিন করুন",
                        "promote": "নির্বাচিত প্রজেক্ট প্রচার করুন"
                    }
                },
                "comments": {
                    "titles": {
                        "main": "মন্তব্য",
                        "index": "মন্তব্য তালিকা",
                        "edit": "মন্তব্য সম্পাদনা করুন"
                    },
                    "actions": {
                        "destroy": "নির্বাচিত মন্তব্য মুছুন",
                        "active": "সক্রিয় নির্বাচিত মন্তব্যসমূহ"
                    }
                }
            },
            "frontend": {
                "titles": {
                    "home": "হোম",
                    "about": "আমাদের সম্পর্কে",
                    "contact": "যোগাযোগ",
                    "blog": "ব্লগ",
                    "page": "পেজ",
                    "project": "প্রজেক্ট",
                    "message_sent": "বার্তা প্রদান",
                    "legal_mentions": "আইনী উল্লেখ",
                    "administration": "প্রশাসন",
                    "members": "কর্মকর্তাদের তালিকা",
                    "member_list": "কর্মকর্তাদের তালিকা",
                    "gallery": "গ্যাল্যারি"
                },
                "submissions": {
                    "message_sent": "<p>আপনার বার্তাটি সফলভাবে পাঠানো হয়েছে</p>"
                },
                "placeholders": {
                    "locale": "আপনার ভাষা নির্বাচন করুন",
                    "timezone": "আপনার সময় অঞ্চল নির্বাচন করুন"
                },
                "blog": {
                    "published_at": "প্রকাশিত {date}",
                    "published_at_with_owner_linkable": "<a href=\"{link}\">{name}</a> কর্তৃক প্রকাশিত {date}",
                    "tags": "ট্যাগ"
                },
                "page": {
                    "published_at": "প্রকাশিত {date}",
                    "published_at_with_owner_linkable": "<a href=\"{link}\">{name}</a> কর্তৃক প্রকাশিত {date}",
                    "tags": "ট্যাগ"
                },
                "gallery": {
                    "published_at": "প্রকাশিত {date}",
                    "published_at_with_owner_linkable": "<a href=\"{link}\">{name}</a> কর্তৃক প্রকাশিত {date}"
                },
                "project": {
                    "published_at": "প্রকাশিত {date}",
                    "published_at_with_owner_linkable": "<a href=\"{link}\">{name}</a> কর্তৃক  {date} তারিখে প্রকাশিত | <a href=\"{belongs_link}\">{belongs}</a> এর আওতায় প্রকল্প",
                    "ongoing": "চলমান প্রজেক্টসমূহ",
                    "proposed": "প্রস্তাবিত প্রজেক্টসমূহ",
                    "complete": "সম্পূর্ণ প্রজেক্টসমূহ",
                    "titles": {
                        "project_background": "প্রকল্প পটভূমি",
                        "project_description": "প্রকল্প বর্ণনা"
                    }
                },
                "comments": {
                    "title": "মন্তব্য"
                }
            }
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "ব্যবহারকারী ID {user} তৈরি হয়েছে",
                    "updated": "ব্যবহারকারী ID {user} হালনাগাদ হয়েছে",
                    "deleted": "ব্যবহারকারী ID {user} ডিলিট হয়েছে"
                },
                "form_submissions": {
                    "created": "ফর্ম জমা ID {form_submission} তৈরি হয়েছে"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "ওহে !",
                "regards": "শুভেচ্ছা",
                "trouble": "আপনার যদি {action} বোতামটি ক্লিক করতে সমস্যা হয় তবে আপনার ওয়েব ব্রাউজারে নীচের URL টি অনুলিপি করুন এবং পেস্ট করুন:",
                "all_rights_reserved": "সর্বস্বত্ব সংরক্ষিত।"
            },
            "password_reset": {
                "subject": "পাসওয়ার্ড পুনরায় সেট হয়েছে",
                "intro": "আপনি এই ইমেলটি পাচ্ছেন কারণ আমরা আপনার অ্যাকাউন্টের জন্য একটি পাসওয়ার্ড পুনরায় সেট করার অনুরোধ পেয়েছি।",
                "action": "পাসওয়ার্ড রিসেট করুন",
                "outro": "আপনি যদি পাসওয়ার্ড পুনরায় সেট করার অনুরোধ না করেন তবে আর কোনও পদক্ষেপের প্রয়োজন নেই।"
            },
            "contact": {
                "subject": "নতুন যোগাযোগ বার্তা",
                "new_contact": "আপনি একটি নতুন যোগাযোগ বার্তা পেয়েছেন। জমা দেওয়ার বিস্তারিত:"
            },
            "alert": {
                "subject": "[{app_name}] বর্জিত ত্রুটি",
                "message": "আপনি কী অপ্রত্যাশিত সার্ভার বর্জিত ত্রুটি পেয়েছেন যা বার্তাটি: {message}.",
                "trace": "সমস্ত ট্রেস বিশদ:"
            }
        },
        "pagination": {
            "previous": "&laquo; আগে",
            "next": "পরে &raquo;"
        },
        "passwords": {
            "password": "পাসওয়ার্ডগুলি কমপক্ষে ছয় অক্ষর হতে হবে এবং নিশ্চিতকরণের সাথে মিলতে হবে।",
            "reset": "আপনার পাসওয়ার্ড পুনরায় সেট করা হয়েছে!",
            "sent": "আমরা আপনার পাসওয়ার্ড রিসেট লিঙ্কটি ই-মেইল করেছি!",
            "token": "এই পাসওয়ার্ড পুনরায় সেট টোকেন অবৈধ।",
            "user": "আমরা এই ইমেল ঠিকানা সহ কোনও ব্যবহারকারী খুঁজে পাই নি।"
        },
        "permissions": {
            "categories": {
                "project": "প্রজেক্ট",
                "category": "বিভাগ",
                "menu": "মেনু",
                "blog": "ব্লগ",
                "page": "পেজ",
                "gallery": "গ্যাল্যারি",
                "form": "ফর্ম",
                "access": "প্রবেশ",
                "seo": "SEO",
                "comment": "মন্তব্য"
            },
            "access": {
                "backend": {
                    "display_name": "ব্যাকফাইস অ্যাক্সেস",
                    "description": "প্রশাসনের পৃষ্ঠাগুলিতে অ্যাক্সেস করতে পারে।"
                },
                "generator_builders": {
                    "display_name": "জেনারেটর অ্যাক্সেস",
                    "description": "জেনারেটর নির্মাতাদের পৃষ্ঠাগুলিতে অ্যাক্সেস করতে পারে।"
                }
            },
            "view": {
                "form_settings": {
                    "display_name": "ফর্ম সেটিংস দেখুন",
                    "description": "ফর্ম সেটিংস দেখতে পারেন।"
                },
                "form_submissions": {
                    "display_name": "ফর্ম জমা দেখুন",
                    "description": "ফর্ম জমাগুলি দেখতে পারেন।"
                },
                "users": {
                    "display_name": "ব্যবহারকারীদের দেখুন",
                    "description": "ব্যবহারকারীদের দেখতে পারেন।"
                },
                "roles": {
                    "display_name": "রোল দেখুন",
                    "description": "রোল দেখতে পারেন।"
                },
                "menus": {
                    "display_name": "মেনু দেখুন",
                    "description": "মেনু দেখতে পারেন।"
                },
                "categories": {
                    "display_name": "বিভাগগুলি দেখুন",
                    "description": "বিভাগগুলি দেখতে পারেন।"
                },
                "galleries": {
                    "display_name": "গ্যালারী দেখুন",
                    "description": "গ্যালারী দেখতে পারেন।"
                },
                "metas": {
                    "display_name": "মেটাস দেখুন",
                    "description": "মেটাস দেখতে পারেন।"
                },
                "redirections": {
                    "display_name": "পুনঃনির্দেশগুলি দেখুন",
                    "description": "পুনঃনির্দেশগুলি দেখতে পারে।"
                },
                "posts": {
                    "display_name": "সমস্ত পোস্ট দেখুন",
                    "description": "সমস্ত পোস্ট দেখতে পারেন।"
                },
                "pages": {
                    "display_name": "সমস্ত পৃষ্ঠা দেখুন",
                    "description": "সমস্ত পৃষ্ঠা দেখতে পারেন।"
                },
                "projects": {
                    "display_name": "সমস্ত প্রকল্প দেখুন",
                    "description": "সমস্ত প্রকল্প দেখতে পারেন।"
                },
                "comments": {
                    "display_name": "সমস্ত মন্তব্য দেখুন",
                    "description": "সমস্ত মন্তব্য দেখতে পারেন।"
                },
                "questions": {
                    "display_name": "সমস্ত প্রশ্ন দেখুন",
                    "description": "সব প্রশ্ন দেখতে পারেন।"
                },
                "own": {
                    "posts": {
                        "display_name": "নিজস্ব পোস্ট দেখুন",
                        "description": "নিজস্ব পোস্টগুলি দেখতে পারেন।"
                    },
                    "pages": {
                        "display_name": "নিজস্ব পৃষ্ঠা দেখুন",
                        "description": "নিজস্ব পৃষ্ঠা দেখতে পারেন।"
                    },
                    "projects": {
                        "display_name": "নিজস্ব প্রজেক্ট দেখুন",
                        "description": "নিজস্ব প্রজেক্টগুলি দেখতে পারেন।"
                    }
                }
            },
            "create": {
                "form_settings": {
                    "display_name": "ফর্ম সেটিংস তৈরি করুন",
                    "description": "ফর্ম সেটিংস তৈরি করতে পারেন।"
                },
                "users": {
                    "display_name": "ব্যবহারকারী তৈরি করুন",
                    "description": "ব্যবহারকারী তৈরি করতে পারেন।"
                },
                "roles": {
                    "display_name": "রোল তৈরি করুন",
                    "description": "রোল তৈরি করতে পারেন।"
                },
                "menus": {
                    "display_name": "মেনু তৈরি করুন",
                    "description": "মেনু তৈরি করতে পারে।"
                },
                "categories": {
                    "display_name": "বিভাগগুলি তৈরি করুন",
                    "description": "বিভাগ তৈরি করতে পারে।"
                },
                "galleries": {
                    "display_name": "গ্যালারী তৈরি করুন",
                    "description": "গ্যালারী তৈরি করতে পারেন।"
                },
                "metas": {
                    "display_name": "মেটাগুলি তৈরি করুন",
                    "description": "মেটাগুলি তৈরি করতে পারেন।"
                },
                "redirections": {
                    "display_name": "রীডিরেকশনগুলি তৈরি করুন",
                    "description": "রীডিরেকশনগুলি তৈরি করতে পারেন।"
                },
                "posts": {
                    "display_name": "পোস্ট তৈরি করুন",
                    "description": "সমস্ত পোস্ট তৈরি করতে পারেন।"
                },
                "pages": {
                    "display_name": "পৃষ্ঠাগুলি তৈরি করুন",
                    "description": "সমস্ত পৃষ্ঠা তৈরি করতে পারে।"
                },
                "projects": {
                    "display_name": "প্রজেক্ট তৈরি করুন",
                    "description": "সমস্ত প্রজেক্ট তৈরি করতে পারেন।"
                },
                "comments": {
                    "display_name": "মন্তব্য তৈরি করুন",
                    "description": "সমস্ত মন্তব্য তৈরি করতে পারেন।"
                },
                "questions": {
                    "display_name": "প্রশ্ন তৈরি করুন",
                    "description": "সব প্রশ্ন তৈরি করতে পারে।"
                }
            },
            "edit": {
                "form_settings": {
                    "display_name": "ফর্ম সেটিংস সম্পাদন করুন",
                    "description": "ফর্ম সেটিংস সম্পাদন করতে পারেন।"
                },
                "users": {
                    "display_name": "ব্যবহারকারীদের সম্পাদন করুন",
                    "description": "ব্যবহারকারীদের সম্পাদন করতে পারেন।"
                },
                "roles": {
                    "display_name": "রোল সম্পাদন করুন",
                    "description": "রোল সম্পাদন করতে পারেন।"
                },
                "menus": {
                    "display_name": "মেনুগুলি সম্পাদনা করুন",
                    "description": "মেনু সম্পাদনা করতে পারেন।"
                },
                "categories": {
                    "display_name": "বিভাগগুলি সম্পাদনা করুন",
                    "description": "বিভাগগুলি সম্পাদনা করতে পারে।"
                },
                "galleries": {
                    "display_name": "গ্যালারী সম্পাদনা করুন",
                    "description": "গ্যালারী সম্পাদনা করতে পারেন।"
                },
                "metas": {
                    "display_name": "মেটাগুলি সম্পাদন করুন",
                    "description": "মেটাগুলি সম্পাদন করতে পারেন।"
                },
                "redirections": {
                    "display_name": "রীডিরেকশনগুলি সম্পাদন করুন",
                    "description": "রীডিরেকশনগুলি সম্পাদন করতে পারেন।"
                },
                "posts": {
                    "display_name": "সমস্ত পোস্ট সম্পাদন করুন",
                    "description": "সমস্ত পোস্ট সম্পাদন করতে পারেন।"
                },
                "pages": {
                    "display_name": "সমস্ত পৃষ্ঠা সম্পাদনা করুন",
                    "description": "সমস্ত পৃষ্ঠা সম্পাদনা করতে পারেন।"
                },
                "projects": {
                    "display_name": "সমস্ত প্রজেক্ট সম্পাদন করুন",
                    "description": "সমস্ত প্রজেক্ট সম্পাদন করতে পারেন।"
                },
                "comments": {
                    "display_name": "সমস্ত মন্তব্য সম্পাদনা করুন",
                    "description": "সমস্ত মন্তব্য সম্পাদনা করতে পারেন।"
                },
                "questions": {
                    "display_name": "সমস্ত প্রশ্ন সম্পাদনা করুন",
                    "description": "সমস্ত প্রশ্ন সম্পাদনা করতে পারেন।"
                },
                "own": {
                    "posts": {
                        "display_name": "নিজস্ব পোস্ট সম্পাদন করুন",
                        "description": "নিজস্ব পোস্ট সম্পাদন করতে পারেন।"
                    },
                    "pages": {
                        "display_name": "নিজস্ব পৃষ্ঠা সম্পাদনা করুন",
                        "description": "নিজস্ব পৃষ্ঠা সম্পাদনা করতে পারে।"
                    },
                    "projects": {
                        "display_name": "নিজস্ব প্রজেক্টগুলি সম্পাদন করুন",
                        "description": "নিজস্ব প্রজেক্টগুলি সম্পাদন করতে পারেন।"
                    }
                }
            },
            "delete": {
                "form_settings": {
                    "display_name": "ফর্ম সেটিংস ডিলিট করুন",
                    "description": "ফর্ম সেটিংস ডিলিট করতে পারেন।"
                },
                "form_submissions": {
                    "display_name": "ফর্ম জমাগুলি ডিলিট করুন",
                    "description": "ফর্ম জমাগুলি ডিলিট করতে পারেন।."
                },
                "users": {
                    "display_name": "ব্যবহারকারীদের ডিলিট করুন",
                    "description": "ব্যবহারকারীদের ডিলিট করতে পারেন।"
                },
                "roles": {
                    "display_name": "রোল ডিলিট করুন",
                    "description": "রোল ডিলিট করতে পারেন।"
                },
                "menus": {
                    "display_name": "মেনু মুছুন",
                    "description": "মেনু মুছতে পারে।"
                },
                "categories": {
                    "display_name": "বিভাগগুলি মুছুন",
                    "description": "বিভাগগুলি মুছতে পারে।"
                },
                "galleries": {
                    "display_name": "গ্যালারী মুছুন",
                    "description": "গ্যালারী মুছতে পারেন।"
                },
                "metas": {
                    "display_name": "মেটাগুলি ডিলিট করুন",
                    "description": "মেটাগুলি ডিলিট করতে পারেন।"
                },
                "redirections": {
                    "display_name": "রীডিরেকশনগুলি ডিলিট করুন",
                    "description": "রীডিরেকশনগুলি ডিলিট করতে পারেন।"
                },
                "posts": {
                    "display_name": "সমস্ত পোস্ট ডিলিট করুন",
                    "description": "সমস্ত পোস্ট ডিলিট করতে পারেন।"
                },
                "pages": {
                    "display_name": "সমস্ত পৃষ্ঠা মুছুন",
                    "description": "সমস্ত পৃষ্ঠা মুছতে পারে।"
                },
                "projects": {
                    "display_name": "সমস্ত প্রজেক্ট ডিলিট করুন",
                    "description": "সমস্ত প্রজেক্ট ডিলিট করতে পারেন।"
                },
                "comments": {
                    "display_name": "সমস্ত মন্তব্য মুছুন",
                    "description": "সমস্ত মন্তব্য মুছতে পারেন।"
                },
                "questions": {
                    "display_name": "সমস্ত প্রশ্ন মুছুন",
                    "description": "সমস্ত প্রশ্ন মুছতে পারেন।"
                },
                "own": {
                    "posts": {
                        "display_name": "নিজস্ব পোস্ট ডিলিট করুন",
                        "description": "নিজস্ব পোস্ট ডিলিট করতে পারেন।"
                    },
                    "pages": {
                        "display_name": "নিজস্ব পৃষ্ঠা মুছুন",
                        "description": "নিজস্ব পৃষ্ঠা মুছতে পারে।"
                    },
                    "projects": {
                        "display_name": "নিজস্ব প্রজেক্ট ডিলিট করুন",
                        "description": "নিজস্ব প্রজেক্ট ডিলিট করতে পারেন।"
                    }
                }
            },
            "publish": {
                "posts": {
                    "display_name": "পোস্ট প্রকাশ করুন",
                    "description": "পোস্ট প্রকাশনার পরিচালনা করতে পারেন।"
                },
                "pages": {
                    "display_name": "পৃষ্ঠা প্রকাশ করুন",
                    "description": "পৃষ্ঠাগুলির প্রকাশনা পরিচালনা করতে পারে।"
                },
                "projects": {
                    "display_name": "প্রজেক্টগুলি প্রকাশ করুন",
                    "description": "প্রজেক্টের প্রকাশনা পরিচালনা করতে পারে।"
                }
            },
            "impersonate": {
                "display_name": "ছদ্মবেশী ব্যবহারকারী",
                "description": "অন্যের ব্যবহারকারীর পরিচয়ের মালিকানা নিতে পারে। পরীক্ষার জন্য দরকারী।"
            }
        },
        "routes": {
            "home": "হোমে",
            "about": "সম্পর্কিত",
            "contact": "যোগাযোগ",
            "contact-sent": "পরিচিতির পাঠানো",
            "legal-mentions": "আইনি-উল্লেখ",
            "redactors": "blog/redactors/{user}",
            "project-owner": "project/project-owner/{user}"
        },
        "validation": {
            "accepted": "{attribute} এটি অবশ্যই গ্রহণ করতে হবে।",
            "active_url": "{attribute} এটি  বৈধ URL নয়।",
            "after": "{attribute} এটি অবশ্যই তারিখের পরে  {date} হতে হবে।",
            "after_or_equal": "{attribute} এটি অবশ্যই তারিখের পরে অথবা সমান {date} হতে হবে।",
            "alpha": "{attribute} এটি কেবলমাত্র অক্ষর ধারণ করতে পারে।",
            "alpha_dash": "{attribute} এটি কেবলমাত্র অক্ষর, সংখ্যা এবং ড্যাশ ধারণ করতে পারে।",
            "alpha_num": "{attribute} এটি কেবলমাত্র অক্ষর এবং সংখ্যা ধারণ করতে পারে।",
            "array": "{attribute} এটি অবশ্যই একটি অ্যারে হতে হবে।",
            "before": "{attribute} এটি অবশ্যই তারিখের আগে {date} হতে হবে।",
            "before_or_equal": "{attribute} এটি অবশ্যই তারিখের আগে অথবা সমান {date} হতে হবে।",
            "between": {
                "numeric": "{attribute} এটি অবশ্যই {min} এবং {max} এর মধ্যে হতে হবে।",
                "file": "{attribute} এটি অবশ্যই {min} এবং {max} এর কিলোবাইটের মধ্যে হতে হবে।",
                "string": "{attribute} এটি অবশ্যই {min} এবং {max} এর অক্ষরের মধ্যে হতে হবে।",
                "array": "{attribute} এটি অবশ্যই {min} এবং {max} এর আইটেমের মধ্যে হতে হবে।"
            },
            "boolean": "{attribute} এই ক্ষেত্রটি অবশ্যই সত্য বা মিথ্যা হতে হবে।",
            "confirmed": "{attribute} এটি নিশ্চিতকরণের সাথে মেলে না।",
            "date": "{attribute} এটি  বৈধ তারিখ নয়।",
            "date_format": "{attribute} এটি {format} বিন্যাসের সাথে মেলে না।",
            "different": "এই {attribute} এবং {other} অবশ্যই আলাদা হতে হবে।",
            "digits": "{attribute} এটি অবশ্যই {digits} সংখ্যা হতে হবে।",
            "digits_between": "{attribute} এটি অবশ্যই {min} এবং {max} এর অক্ষরের মধ্যে হতে হবে।",
            "dimensions": "{attribute} এতে অবৈধ চিত্রের মাত্রা রয়েছে।",
            "distinct": "{attribute} এই ক্ষেত্রটির একটি সদৃশ মান রয়েছে।",
            "email": "{attribute} এটি অবশ্যই একটি বৈধ ইমেল ঠিকানা হতে হবে।",
            "exists": "নির্বাচিত {attribute} এটি অবৈধ।",
            "file": "{attribute} এটি অবশ্যই একটি ফাইল হতে হবে।",
            "filled": "{attribute} এই ক্ষেত্রটি অবশ্যই একটি মান থাকতে হবে।",
            "image": "{attribute} এটি অবশ্যই একটি চিত্র হতে হবে।",
            "in": "নির্বাচিত {attribute} এটি অবৈধ।",
            "in_array": "{attribute} এই ক্ষেত্রটি {other} এর মধ্যে বিদ্যমান নেই।",
            "integer": "{attribute} এটি অবশ্যই একটি পূর্ণসংখ্যা হতে হবে।",
            "ip": "{attribute} এটি অবশ্যই একটি বৈধ আইপি ঠিকানা হতে হবে।",
            "ipv4": "{attribute} এটি অবশ্যই একটি বৈধ আইপিv4 ঠিকানা হতে হবে।",
            "ipv6": "{attribute} এটি অবশ্যই একটি বৈধ আইপিv6 ঠিকানা হতে হবে।",
            "json": "{attribute} এটি অবশ্যই একটি বৈধ JSON স্ট্রিং হতে হবে।",
            "max": {
                "numeric": "{attribute} এটি {max} এর চেয়ে বেশি নাও হতে পারে।",
                "file": "{attribute} এটি {max} কিলোবাইট এর চেয়ে বেশি নাও হতে পারে।",
                "string": "{attribute} এটি {max} অক্ষর এর চেয়ে বেশি নাও হতে পারে।",
                "array": "{attribute} এটি {max} আইটেম এর চেয়ে বেশি নাও হতে পারে।"
            },
            "mimes": "{attribute} এটি অবশ্যই একটি ফাইল টাইপ হতে হবে: {values}.",
            "mimetypes": "{attribute} এটি অবশ্যই একটি ফাইল টাইপ হতে হবে: {values}.",
            "min": {
                "numeric": "{attribute} এটি অবশ্যই কমপক্ষে {min} হতে হবে।",
                "file": "{attribute} এটি অবশ্যই কমপক্ষে {min} কিলোবাইট হতে হবে।",
                "string": "{attribute} এটি অবশ্যই কমপক্ষে {min} অক্ষর হতে হবে।",
                "array": "{attribute} এটি অবশ্যই কমপক্ষে {min} আইটেম হতে হবে।"
            },
            "not_in": "নির্বাচিত {attribute} এটি অবৈধ।",
            "not_regex": "{attribute} এই  বিন্যাসটি অবৈধ।",
            "numeric": "{attribute} এটি অবশ্যই একটি সংখ্যা হতে হবে।",
            "present": "{attribute} এই ক্ষেত্রটি অবশ্যই উপস্থিত থাকতে হবে।",
            "regex": "{attribute} এই  বিন্যাসটি অবৈধ।",
            "required": "{attribute} এই ক্ষেত্রটি প্রয়োজন",
            "required_if": "{attribute} এই ক্ষেত্রটি প্রয়োজন {other} যখন {value}.",
            "required_unless": "{attribute} এই ক্ষেত্রটি প্রয়োজন যদি না {other} {values} এর মধ্যে না থাকে।",
            "required_with": "{attribute} এই ক্ষেত্রটি প্রয়োজন যখন {values} উপস্থিত।",
            "required_with_all": "{attribute} এই ক্ষেত্রটি প্রয়োজন যখন {values} উপস্থিত।",
            "required_without": "{attribute} এই ক্ষেত্রটি প্রয়োজন যখন {values} অনুপস্থিত।",
            "required_without_all": "{attribute} এই ক্ষেত্রটি প্রয়োজন যখন {values} উপস্থিত নেই।",
            "same": "এই {attribute} এবং {other} অবশ্যই মিলতে হবে।",
            "size": {
                "numeric": "এই {attribute} অবশ্যই {size}.",
                "file": "এই {attribute} অবশ্যই {size} কিলোবাইট।",
                "string": "এই {attribute} অবশ্যই {size} অক্ষর।",
                "array": "এই {attribute} অবশ্যই {size} আইটেম।"
            },
            "string": "এই {attribute} অবশ্যই একটি স্ট্রিং হতে হবে।",
            "timezone": "এই {attribute} অবশ্যই একটি বৈধ জোন হতে হবে।",
            "unique": "{attribute} এটি ইতিমধ্যে নেওয়া হয়েছে।",
            "uploaded": "{attribute} এটি আপলোড হতে ব্যর্থ হয়েছে।",
            "url": "{attribute} এই বিন্যাসটি অবৈধ।",
            "custom": {
                "attribute-name": {
                    "rule-name": "কাস্টম বার্তা"
                }
            },
            "attributes": {
                "name": "নাম",
                "display_name": "নাম",
                "username": "ছদ্ম",
                "email": "ই-মেইল",
                "first_name": "নামের প্রথম অংশ",
                "last_name": "নামের শেষাংশ",
                "password": "পাসওয়ার্ড",
                "password_confirmation": "পাসওয়ার্ড নিশ্চিত করুন",
                "old_password": "পুরানো পাসওয়ার্ড",
                "new_password": "নতুন পাসওয়ার্ড",
                "new_password_confirmation": "নতুন পাসওয়ার্ড নিশ্চিত করুন",
                "postal_code": "পোস্ট অফিসের নাম্বার",
                "city": "শহর",
                "country": "দেশ",
                "address": "ঠিকানা",
                "phone": "ফোন",
                "mobile": "মোবাইল",
                "age": "বয়স",
                "sex": "লিঙ্গ",
                "gender": "লিঙ্গ",
                "day": "দিন",
                "month": "মাস",
                "year": "বছর",
                "hour": "ঘন্টা",
                "minute": "মিনিট",
                "second": "সেকেন্ড",
                "title": "শিরোনাম",
                "content": "সন্তুষ্ট",
                "description": "বিবরণ",
                "summary": "সারাংশ",
                "excerpt": "উদ্ধৃতাংশ",
                "date": "তারিখ",
                "time": "সময়",
                "available": "সহজলভ্য",
                "size": "আয়তন",
                "roles": "ভূমিকা",
                "permissions": "অনুমতিসমূহ",
                "active": "সক্রিয়",
                "message": "বার্তা",
                "g-recaptcha-response": "ক্যাপচা",
                "locale": "স্থানীয়করণ",
                "route": "রুট",
                "url": "URL উপনাম",
                "form_type": "ফর্মের ধরন",
                "form_data": "ফর্ম ডেটা",
                "recipients": "প্রাপক",
                "source_path": "আসল পথ",
                "target_path": "লক্ষ্য পথ",
                "redirect_type": "রীডিরেক্টের ধরন",
                "timezone": "সময় অঞ্চল",
                "order": "প্রদর্শন আদেশ",
                "image": "চিত্র",
                "video": "ভিডিও",
                "document": "নথি",
                "report": "প্রতিবেদন",
                "default_image": "ডিফল্ট চিত্র",
                "project_image": "প্রজেক্ট চিত্র",
                "project_images": "প্রজেক্ট চিত্র সমূহ",
                "project_videos": "প্রজেক্ট ভিডিও সমূহ",
                "project_documents": "প্রজেক্ট নথি",
                "project_reports": "প্রকল্প রিপোর্ট",
                "type": "ধরন",
                "zone": "জোন",
                "circle": "সার্কেল",
                "division": "বিভাগ",
                "status": "স্ট্যাটাস",
                "pinned": "পিন করা",
                "promoted": "উন্নীত",
                "body": "শরীর",
                "tags": "ট্যাগ",
                "published_at": "প্রকাশিত",
                "unpublished_at": "অপ্রকাশিত",
                "beginning_date": "শুরুর তারিখ",
                "finishing_date": "সমাপ্তির তারিখ",
                "metable_type": "সত্তা",
                "team_name": "দলের নাম",
                "team_description": "দলের বিবরণ",
                "team_lead": "প্রধান পরিদর্শক",
                "team_members": "দলের সদস্যরা",
                "meeting_lead": "চেয়ার দ্বারা",
                "meeting_members": "অংশগ্রহণকারী সদস্য",
                "link": "লিংক",
                "venue": "ঘটনাস্থল",
                "organised_by": "সংগঠিত",
                "chair_by": "চেয়ার দ্বারা",
                "designation": "উপাধি",
                "superior": "উচ্চতর",
                "avatar": "অবতার",
                "commenter": "মন্তব্যকারী",
                "parent_category": "মূল বিভাগ",
                "category": "বিভাগ",
                "parent_menu": "মুল মেনু",
                "0": "menu"
            }
        }
    },
    "en": {
        "alerts": {
            "backend": {
                "users": {
                    "created": "User created",
                    "updated": "User updated",
                    "deleted": "User deleted",
                    "bulk_destroyed": "Selected users deleted",
                    "bulk_enabled": "Selected users enabled",
                    "bulk_disabled": "Selected users disabled"
                },
                "roles": {
                    "created": "Role created",
                    "updated": "Role updated",
                    "deleted": "Role deleted"
                },
                "members": {
                    "created": "Member created",
                    "updated": "Member updated",
                    "deleted": "Member deleted",
                    "bulk_destroyed": "Selected members deleted",
                    "bulk_active": "Selected members activated"
                },
                "metas": {
                    "created": "Meta created",
                    "updated": "Meta updated",
                    "deleted": "Meta deleted",
                    "bulk_destroyed": "Selected metas deleted"
                },
                "form_submissions": {
                    "deleted": "Submission deleted",
                    "bulk_destroyed": "Selected submissions deleted"
                },
                "form_settings": {
                    "created": "Form setting created",
                    "updated": "Form setting updated",
                    "deleted": "Form setting deleted"
                },
                "redirections": {
                    "created": "Redirection created",
                    "updated": "Redirection updated",
                    "deleted": "Redirection deleted",
                    "bulk_destroyed": "Selected redirections deleted",
                    "bulk_enabled": "Selected redirections enabled",
                    "bulk_disabled": "Selected redirections disabled",
                    "file_imported": "File successfully imported"
                },
                "categories": {
                    "created": "Category created",
                    "updated": "Category updated",
                    "deleted": "Category deleted",
                    "bulk_destroyed": "Selected Categories deleted",
                    "bulk_active": "Selected Categories activated"
                },
                "menus": {
                    "created": "Menu created",
                    "updated": "Menu updated",
                    "deleted": "Menu deleted",
                    "bulk_destroyed": "Selected Menus deleted",
                    "bulk_active": "Selected Menus activated"
                },
                "galleries": {
                    "created": "Gallery created",
                    "updated": "Gallery updated",
                    "deleted": "Gallery deleted",
                    "bulk_destroyed": "Selected galleries deleted",
                    "bulk_active": "Selected galleries activated"
                },
                "videos": {
                    "created": "Video created",
                    "updated": "Video updated",
                    "deleted": "Video deleted",
                    "bulk_destroyed": "Selected videos deleted",
                    "bulk_active": "Selected videos activated"
                },
                "events": {
                    "created": "Event created",
                    "updated": "Event updated",
                    "deleted": "Event deleted",
                    "bulk_destroyed": "Selected events deleted",
                    "bulk_active": "Selected events activated"
                },
                "documents": {
                    "created": "Document created",
                    "updated": "Document updated",
                    "deleted": "Document deleted",
                    "bulk_destroyed": "Selected documents deleted",
                    "bulk_active": "Selected documents activated"
                },
                "news": {
                    "created": "News created",
                    "updated": "News updated",
                    "deleted": "News deleted",
                    "bulk_destroyed": "Selected news deleted",
                    "bulk_active": "Selected news activated"
                },
                "posts": {
                    "created": "Post created",
                    "updated": "Post updated",
                    "deleted": "Post deleted",
                    "bulk_destroyed": "Selected posts deleted",
                    "bulk_published": "Selected posts published",
                    "bulk_pending": "Selected posts are awaiting moderation",
                    "bulk_pinned": "Selected posts pinned",
                    "bulk_promoted": "Selected posts promoted"
                },
                "pages": {
                    "created": "Page created",
                    "updated": "Page updated",
                    "deleted": "Page deleted",
                    "bulk_destroyed": "Selected pages deleted",
                    "bulk_published": "Selected pages published",
                    "bulk_pending": "Selected pages are awaiting moderation",
                    "bulk_pinned": "Selected pages pinned",
                    "bulk_promoted": "Selected pages promoted"
                },
                "projects": {
                    "created": "Project created",
                    "updated": "Project updated",
                    "deleted": "Project deleted",
                    "bulk_destroyed": "Selected projects deleted",
                    "bulk_published": "Selected projects published",
                    "bulk_pending": "Selected projects are awaiting moderation",
                    "bulk_pinned": "Selected projects pinned",
                    "bulk_promoted": "Selected projects promoted"
                },
                "testimonials": {
                    "created": "Testimonial created",
                    "updated": "Testimonial updated",
                    "deleted": "Testimonial deleted",
                    "bulk_destroyed": "Selected testimonials deleted",
                    "bulk_active": "Selected testimonials activated"
                },
                "actions": {
                    "invalid": "Invalid action"
                }
            },
            "frontend": {
                "user": {
                    "password_updated": "Password successfully updated."
                }
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "manage": "Manage",
            "edit": "Edit",
            "update": "Update",
            "view": "View",
            "preview": "Preview",
            "back": "Back",
            "send": "Send",
            "login-as": "Login as {name}",
            "apply": "Apply",
            "post": "Post",
            "submit": "Submit",
            "ask_questions": "Ask Questions",
            "comments": "Comments",
            "add": "Add",
            "remove": "Remove",
            "post_comment": "Leave a Comment",
            "users": {
                "create": "Create user"
            },
            "roles": {
                "create": "Create role"
            },
            "members": {
                "create": "Create members"
            },
            "metas": {
                "create": "Create meta"
            },
            "form_settings": {
                "create": "Create setting"
            },
            "redirections": {
                "create": "Create redirection",
                "import": "Import CSV"
            },
            "menus": {
                "main": "Menu",
                "create": "Create Menu"
            },
            "galleries": {
                "main": "Gallery",
                "create": "Create Gallery"
            },
            "videos": {
                "main": "Video",
                "create": "Create Video"
            },
            "events": {
                "main": "Event",
                "create": "Create Event"
            },
            "documents": {
                "main": "Document",
                "create": "Create Document"
            },
            "news": {
                "main": "News",
                "create": "Create News"
            },
            "categories": {
                "main": "Category",
                "create": "Create category"
            },
            "posts": {
                "create": "Create post",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft"
            },
            "pages": {
                "create": "Create page",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft"
            },
            "projects": {
                "create": "Create project",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft"
            }
        },
        "exceptions": {
            "general": "Server exception",
            "unauthorized": "Action not allowed",
            "backend": {
                "users": {
                    "create": "Error on user creation",
                    "update": "Error on user updating",
                    "delete": "Error on user deletion",
                    "first_user_cannot_be_edited": "You cannot edit super admin user",
                    "first_user_cannot_be_disabled": "Super admin user cannot be disabled",
                    "first_user_cannot_be_destroyed": "Super admin user cannot be deleted",
                    "first_user_cannot_be_impersonated": "Super admin user cannot be impersonated",
                    "cannot_set_superior_roles": "You cannot attribute roles superior to yours"
                },
                "roles": {
                    "create": "Error on role creation",
                    "update": "Error on role updating",
                    "delete": "Error on role deletion"
                },
                "metas": {
                    "create": "Error on meta creation",
                    "update": "Error on meta updating",
                    "delete": "Error on meta deletion",
                    "already_exist": "There is already a meta for this locale route"
                },
                "menus": {
                    "create": "Error on menu creation",
                    "update": "Error on menu updating",
                    "delete": "Error on menu deletion"
                },
                "form_submissions": {
                    "create": "Error on submission creation",
                    "delete": "Error on submission deletion"
                },
                "form_settings": {
                    "create": "Error on form setting creation",
                    "update": "Error on form setting updating",
                    "delete": "Error on form setting deletion",
                    "already_exist": "There is already a setting linked to this form"
                },
                "redirections": {
                    "create": "Error on redirection creation",
                    "update": "Error on redirection updating",
                    "delete": "Error on redirection deletion",
                    "already_exist": "There is already a redirection for this path"
                },
                "posts": {
                    "create": "Error on post creation",
                    "update": "Error on post updating",
                    "save": "Error on post saving",
                    "delete": "Error on post deletion"
                },
                "pages": {
                    "create": "Error on page creation",
                    "update": "Error on page updating",
                    "save": "Error on page saving",
                    "delete": "Error on page deletion"
                },
                "projects": {
                    "create": "Error on project creation",
                    "update": "Error on project updating",
                    "save": "Error on project saving",
                    "delete": "Error on project deletion"
                },
                "galleries": {
                    "create": "Error on gallery creation",
                    "update": "Error on gallery updating",
                    "save": "Error on gallery saving",
                    "delete": "Error on gallery deletion"
                },
                "videos": {
                    "create": "Error on video creation",
                    "update": "Error on video updating",
                    "save": "Error on video saving",
                    "delete": "Error on video deletion"
                },
                "events": {
                    "create": "Error on event creation",
                    "update": "Error on event updating",
                    "save": "Error on event saving",
                    "delete": "Error on event deletion"
                },
                "documents": {
                    "create": "Error on document creation",
                    "update": "Error on document updating",
                    "save": "Error on document saving",
                    "delete": "Error on document deletion"
                },
                "news": {
                    "create": "Error on news creation",
                    "update": "Error on news updating",
                    "save": "Error on news saving",
                    "delete": "Error on news deletion"
                },
                "testimonials": {
                    "create": "Error on testimonials creation",
                    "update": "Error on testimonials updating",
                    "save": "Error on testimonials saving",
                    "delete": "Error on testimonials deletion"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "That e-mail address is already taken.",
                    "password_mismatch": "That is not your old password.",
                    "delete_account": "Error on account deletion.",
                    "updating_disabled": "Account editing is disabled."
                },
                "auth": {
                    "registration_disabled": "Registration is disabled."
                }
            }
        },
        "forms": {
            "contact": {
                "display_name": "Contact form"
            }
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "delete_report": "Delete report",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "rainforum_overview": "RAiN Forum is a non-profit voluntary platform for knowledge sharing and promotion of safe water\n     among scientist, engineers, architects, planners, academicians, practitioners, administrators, students and others who\n      are directly or indirectly concerned in sustainable water management.",
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "morphs": {
                "post": "Post, identity {id}",
                "project": "project, identity {id}",
                "user": "User, identity {id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "bn": "Bangla"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag",
                "type": "Select type",
                "chart_type": "Select chart type",
                "status": "Select a status",
                "team_description": "Team description",
                "team_lead": "Chief Inspector",
                "team_members": "Team members",
                "project_belongs_to": "Select project belongs",
                "meeting_lead": "Chair by",
                "meeting_members": "Participant members",
                "position": "Position",
                "superior": "Superior",
                "order": "Order",
                "parent_category": "Parent Category",
                "category": "Category",
                "parent_menu": "Parent Menu"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg.",
                "allowed_document_types": "Allowed types: pdf.",
                "allowed_video_types": "Allowed types: mp4, mov, wmv.",
                "allowed_report_types": "Allowed types: pdf."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "register": "Register",
                "register_invite": "Don't have an account?",
                "login_invite": "Already have an account?",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrator",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>.",
                "no_data_registered": "No Record registered yet!"
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts",
                    "new_projects": "New projects",
                    "pending_projects": "Pending projects",
                    "published_projects": "Published projects",
                    "ongoing_projects": "Ongoing projects",
                    "complete_projects": "Complete projects",
                    "proposed_projects": "Proposed projects",
                    "last_projects": "Last projects",
                    "last_published_projects": "Last publications",
                    "last_pending_projects": "Last pending projects",
                    "last_new_projects": "Last new projects",
                    "all_projects": "All projects"
                },
                "new_menu": {
                    "project": "New project",
                    "zone": "New zone",
                    "post": "New post",
                    "form_setting": "New form setting",
                    "user": "New user",
                    "role": "New role",
                    "meta": "New meta",
                    "redirection": "New redirection"
                },
                "sidebar": {
                    "content": "Content management",
                    "project": "Project management",
                    "forms": "Form management",
                    "access": "Access management",
                    "seo": "SEO settings",
                    "setting": "Settings",
                    "media": "Media Management",
                    "event": "Event Management"
                },
                "titles": {
                    "dashboard": "Dashboard",
                    "generator_builder": "Generator Builder",
                    "log_viewer": "Log viewer"
                },
                "users": {
                    "titles": {
                        "main": "User",
                        "index": "User list",
                        "create": "User creation",
                        "edit": "User modification"
                    },
                    "actions": {
                        "destroy": "Delete selected users",
                        "enable": "Enable selected users",
                        "disable": "Disable selected users"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "Role",
                        "index": "Role list",
                        "create": "Role creation",
                        "edit": "Role modification"
                    }
                },
                "members": {
                    "titles": {
                        "main": "Member",
                        "index": "Member list",
                        "create": "Member creation",
                        "edit": "Member modification"
                    },
                    "types": {
                        "general": "General Members",
                        "supportive": "Supportive",
                        "adviser": "Adviser",
                        "associate": "Associate",
                        "executive": "Executive Committee"
                    },
                    "statuses": {
                        "inactive": "Inactive",
                        "active": "Active"
                    },
                    "actions": {
                        "destroy": "Delete selected members",
                        "active": "Active selected members"
                    }
                },
                "metas": {
                    "titles": {
                        "main": "Meta",
                        "index": "Meta list",
                        "create": "Meta creation",
                        "edit": "Meta modification"
                    },
                    "actions": {
                        "destroy": "Delete selected metas"
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "Submission",
                        "index": "Submission list",
                        "show": "Submission detail"
                    },
                    "actions": {
                        "destroy": "Delete selected submissions"
                    }
                },
                "form_settings": {
                    "titles": {
                        "main": "Forms",
                        "index": "Form setting list",
                        "create": "Form setting creation",
                        "edit": "Form setting modification"
                    },
                    "descriptions": {
                        "recipients": "Example: 'webmaster@example.com' or 'sales@example.com,support@example.com' . To specify multiple recipients, separate each email address with a comma.",
                        "message": "The message to display to the user after submission of this form. Leave blank for no message."
                    }
                },
                "redirections": {
                    "titles": {
                        "main": "Redirection",
                        "index": "Redirection list",
                        "create": "Redirection creation",
                        "edit": "Redirection modification"
                    },
                    "actions": {
                        "destroy": "Delete selected redirections",
                        "enable": "Enable selected redirections",
                        "disable": "Disable selected redirections"
                    },
                    "types": {
                        "permanent": "Permanent redirect (301)",
                        "temporary": "Temporary redirect (302)"
                    },
                    "import": {
                        "title": "CSV file import",
                        "label": "Select CSV file to import",
                        "description": "File must have 2 columns with \"source\" and \"target\" as heading, redirection will be permanent by default"
                    }
                },
                "menus": {
                    "titles": {
                        "main": "Menu",
                        "index": "Menu list",
                        "create": "Menu creation",
                        "edit": "Menu modification"
                    },
                    "statuses": {
                        "inactive": "Inactive",
                        "active": "Active"
                    },
                    "categories": {
                        "mainmenu": "Main menu",
                        "topmenu": "Top menu",
                        "bottommenu": "Bottom menu",
                        "footermenu": "Footer menu"
                    },
                    "actions": {
                        "destroy": "Delete selected Menus"
                    }
                },
                "categories": {
                    "titles": {
                        "main": "Category",
                        "index": "Category list",
                        "create": "Category creation",
                        "edit": "Category modification"
                    },
                    "statuses": {
                        "inactive": "Inactive",
                        "active": "Active"
                    },
                    "actions": {
                        "destroy": "Delete selected Categories"
                    }
                },
                "galleries": {
                    "titles": {
                        "main": "Gallery",
                        "index": "Gallery list",
                        "create": "Gallery creation",
                        "edit": "Gallery modification"
                    },
                    "statuses": {
                        "inactive": "Inactive",
                        "active": "Active"
                    },
                    "actions": {
                        "destroy": "Delete selected galleries"
                    }
                },
                "videos": {
                    "titles": {
                        "main": "Video",
                        "index": "Video list",
                        "create": "Video creation",
                        "edit": "Video modification"
                    },
                    "statuses": {
                        "inactive": "Inactive",
                        "active": "Active"
                    },
                    "types": {
                        "rainforum": "Rain Forum",
                        "others": "Others"
                    },
                    "actions": {
                        "destroy": "Delete selected videos"
                    }
                },
                "events": {
                    "titles": {
                        "main": "Event",
                        "index": "Event list",
                        "create": "Event creation",
                        "edit": "Event modification"
                    },
                    "statuses": {
                        "inactive": "Inactive",
                        "active": "Active"
                    },
                    "types": {
                        "training": "Training",
                        "workshop": "Workshop",
                        "seminar": "Seminar"
                    },
                    "accesses": {
                        "free": "Free",
                        "commercial": "Commercial"
                    },
                    "actions": {
                        "destroy": "Delete selected events"
                    }
                },
                "documents": {
                    "titles": {
                        "main": "Document",
                        "index": "Document list",
                        "create": "Document creation",
                        "edit": "Document modification"
                    },
                    "statuses": {
                        "inactive": "Inactive",
                        "active": "Active"
                    },
                    "types": {
                        "papers": "Papers",
                        "books": "Books",
                        "project_documents": "Project Documents",
                        "training_materials": "Training Materials"
                    },
                    "actions": {
                        "destroy": "Delete selected documents"
                    }
                },
                "news": {
                    "titles": {
                        "main": "News",
                        "index": "News list",
                        "create": "News creation",
                        "edit": "News modification"
                    },
                    "statuses": {
                        "inactive": "Inactive",
                        "active": "Active"
                    },
                    "categories": {
                        "training": "Training",
                        "workshop": "Workshop",
                        "seminar": "Seminar",
                        "rain": "Rain News"
                    },
                    "actions": {
                        "destroy": "Delete selected news"
                    }
                },
                "posts": {
                    "statuses": {
                        "draft": "Draft",
                        "pending": "Pending",
                        "published": "Published"
                    },
                    "titles": {
                        "main": "Posts",
                        "index": "Post list",
                        "create": "Create post",
                        "edit": "Edit post",
                        "publication": "Publication options"
                    },
                    "descriptions": {
                        "meta_title": "If leave empty, title will be that of article' title by default.",
                        "meta_description": "If leave empty, description will be that of article's summary by default."
                    },
                    "placeholders": {
                        "body": "Write your content...",
                        "meta_title": "Article's title.",
                        "meta_description": "Article's summary."
                    },
                    "actions": {
                        "destroy": "Delete selected posts",
                        "publish": "Publish selected posts",
                        "pin": "Pin selected posts",
                        "promote": "Promote selected posts"
                    }
                },
                "pages": {
                    "statuses": {
                        "draft": "Draft",
                        "pending": "Pending",
                        "published": "Published"
                    },
                    "titles": {
                        "main": "Pages",
                        "index": "Page list",
                        "create": "Create page",
                        "edit": "Edit page",
                        "publication": "Publication options"
                    },
                    "descriptions": {
                        "meta_title": "If leave empty, title will be that of article' title by default.",
                        "meta_description": "If leave empty, description will be that of article's summary by default."
                    },
                    "placeholders": {
                        "body": "Write your content...",
                        "meta_title": "Article's title.",
                        "meta_description": "Article's summary."
                    },
                    "actions": {
                        "destroy": "Delete selected pages",
                        "publish": "Publish selected pages",
                        "pin": "Pin selected pages",
                        "promote": "Promote selected pages"
                    }
                },
                "projects": {
                    "statuses": {
                        "unpublished": "Unpublished",
                        "published": "Published"
                    },
                    "types": {
                        "upcoming": "Upcoming",
                        "ongoing": "Ongoing",
                        "complete": "Complete"
                    },
                    "titles": {
                        "main": "Projects",
                        "index": "Project list",
                        "create": "Create project",
                        "edit": "Edit project",
                        "publication": "Publication options"
                    },
                    "descriptions": {
                        "meta_title": "If leave empty, title will be that of article' title by default.",
                        "meta_description": "If leave empty, description will be that of article's summary by default."
                    },
                    "placeholders": {
                        "body": "Write your content...",
                        "meta_title": "Article's title.",
                        "meta_description": "Article's summary."
                    },
                    "actions": {
                        "destroy": "Delete selected projects",
                        "publish": "Publish selected projects",
                        "pin": "Pin selected projects",
                        "promote": "Promote selected projects"
                    }
                },
                "comments": {
                    "titles": {
                        "main": "Comments",
                        "index": "Comments list",
                        "edit": "Edit Comments"
                    },
                    "actions": {
                        "destroy": "Delete selected Comments",
                        "active": "Active selected Comments"
                    }
                },
                "testimonials": {
                    "titles": {
                        "main": "Testimonials",
                        "index": "Testimonials list",
                        "edit": "Edit Testimonials"
                    },
                    "statuses": {
                        "inactive": "Inactive",
                        "active": "Active"
                    },
                    "actions": {
                        "destroy": "Delete selected Testimonials",
                        "active": "Publish selected Testimonials"
                    }
                }
            },
            "frontend": {
                "titles": {
                    "home": "Home",
                    "about": "About",
                    "contact": "Contact",
                    "blog": "Blog",
                    "blog_create": "Write Blog",
                    "page": "Page",
                    "project": "Project",
                    "message_sent": "Message sent",
                    "legal_mentions": "Legal mentions",
                    "site-map": "Site Map",
                    "administration": "Administration",
                    "members": "Members",
                    "member_list": "Members List",
                    "gallery": "Gallery",
                    "video": "Video",
                    "event": "Event",
                    "document": "Document",
                    "news": "News",
                    "weather": "Weather Report",
                    "testimonial": "Comments",
                    "member": "Members",
                    "rwh_calculator": "RWH Calculator"
                },
                "messages": {
                    "comment_invite": "Be the first to share what you think",
                    "read_more": "Read More",
                    "save_water": "Save Water"
                },
                "submissions": {
                    "message_sent": "<p>Your message has been successfully sent</p>"
                },
                "placeholders": {
                    "locale": "Select your language",
                    "timezone": "Select your timezone"
                },
                "blog": {
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "tags": "Tags",
                    "my_post": "My Blog",
                    "latest_post": "Latest Blog",
                    "tag_post": "Blog Tags",
                    "create_blog": "Write Blog"
                },
                "menus": {
                    "titles": {
                        "main": "Menu"
                    },
                    "categories": {
                        "mainmenu": "Main menu",
                        "topmenu": "Top menu",
                        "bottommenu": "Bottom menu",
                        "footermenu": "Footer menu"
                    }
                },
                "page": {
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "tags": "Tags"
                },
                "gallery": {
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>"
                },
                "video": {
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>"
                },
                "event": {
                    "category": "Category",
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "types": {
                        "training": "Training",
                        "workshop": "Workshop",
                        "seminar": "Seminar"
                    }
                },
                "document": {
                    "category": "Category",
                    "latest_document": "Latest Documents",
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "types": {
                        "papers": "Papers",
                        "books": "Books",
                        "project_documents": "Project Documents",
                        "training_materials": "Training Materials"
                    }
                },
                "news": {
                    "source": "Source",
                    "category": "Category",
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>"
                },
                "project": {
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "ongoing": "Ongoing Projects",
                    "proposed": "Proposed Projects",
                    "complete": "Complete Projects",
                    "titles": {
                        "project_background": "Project Background",
                        "project_description": "Project Description"
                    },
                    "types": {
                        "upcoming": "Upcoming Projects",
                        "ongoing": "Ongoing Projects",
                        "complete": "Complete Projects"
                    }
                },
                "comments": {
                    "title": "Comments"
                },
                "testimonial": {
                    "create_testimonial": "Submit Comment",
                    "latest_comment": "Latest Comment",
                    "published_at": "{date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "invitation": "Please, leave your comments/suggestion about the RAiN Forum website."
                },
                "members": {
                    "latest_members": "Latest Members",
                    "member_details": "Member Details",
                    "attributes": {
                        "image": "Photo",
                        "name": "Name",
                        "type": "Member Type",
                        "designation": "Designation",
                        "organization": "Organization",
                        "batch": "Batch",
                        "working_sector": "Working Sector",
                        "phone": "Phone",
                        "email": "E-mail",
                        "detail": "Detail",
                        "address": "Address",
                        "bio": "Biography"
                    },
                    "types": {
                        "general": "General Members",
                        "supportive": "Supportive Members",
                        "adviser": "Adviser",
                        "associate": "Associate Members",
                        "executive": "Executive Committee"
                    }
                }
            }
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "User ID {user} created",
                    "updated": "User ID {user} updated",
                    "deleted": "User ID {user} deleted"
                },
                "form_submissions": {
                    "created": "Form submission ID {form_submission} created"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "Hello !",
                "regards": "Regards",
                "trouble": "If you’re having trouble clicking the {action} button, copy and paste the URL below into your web browser :",
                "all_rights_reserved": "All rights reserved."
            },
            "password_reset": {
                "subject": "Password reset",
                "intro": "You are receiving this email because we received a password reset request for your account.",
                "action": "Reset Password",
                "outro": "If you did not request a password reset, no further action is required."
            },
            "contact": {
                "subject": "New contact message",
                "new_contact": "You've got a new contact message. Submission detail :"
            },
            "alert": {
                "subject": "[{app_name}] Exception error",
                "message": "You've got unexpected server exception error which message is : {message}.",
                "trace": "All trace detail :"
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "permissions": {
            "categories": {
                "project": "Project",
                "category": "Category",
                "menu": "Menu",
                "blog": "Blog",
                "page": "Page",
                "gallery": "Gallery",
                "video": "Video",
                "form": "Forms",
                "access": "Access",
                "seo": "SEO",
                "comment": "Comment",
                "event": "Event",
                "news": "News",
                "document": "Document",
                "testimonial": "Testimonial"
            },
            "access": {
                "backend": {
                    "display_name": "Backend access",
                    "description": "Can access to administration pages."
                },
                "generator_builders": {
                    "display_name": "Generator access",
                    "description": "Can access to generator builder pages."
                }
            },
            "view": {
                "form_settings": {
                    "display_name": "View form settings",
                    "description": "Can view form settings."
                },
                "form_submissions": {
                    "display_name": "View form submissions",
                    "description": "Can view form submissions."
                },
                "users": {
                    "display_name": "View users",
                    "description": "Can view users."
                },
                "roles": {
                    "display_name": "View roles",
                    "description": "Can view roles."
                },
                "members": {
                    "display_name": "View members",
                    "description": "Can view members."
                },
                "menus": {
                    "display_name": "View menus",
                    "description": "Can view menus."
                },
                "categories": {
                    "display_name": "View categories",
                    "description": "Can view categories."
                },
                "galleries": {
                    "display_name": "View galleries",
                    "description": "Can view galleries."
                },
                "videos": {
                    "display_name": "View videos",
                    "description": "Can view videos."
                },
                "events": {
                    "display_name": "View events",
                    "description": "Can view events."
                },
                "documents": {
                    "display_name": "View documents",
                    "description": "Can view documents."
                },
                "testimonials": {
                    "display_name": "View testimonials",
                    "description": "Can view testimonials."
                },
                "news": {
                    "display_name": "View news",
                    "description": "Can view news."
                },
                "metas": {
                    "display_name": "View metas",
                    "description": "Can view metas."
                },
                "redirections": {
                    "display_name": "View redirections",
                    "description": "Can view redirections."
                },
                "posts": {
                    "display_name": "View all posts",
                    "description": "Can view all posts."
                },
                "pages": {
                    "display_name": "View all pages",
                    "description": "Can view all pages."
                },
                "projects": {
                    "display_name": "View all projects",
                    "description": "Can view all projects."
                },
                "comments": {
                    "display_name": "View all comments",
                    "description": "Can view all comments."
                },
                "questions": {
                    "display_name": "View all questions",
                    "description": "Can view all questions."
                },
                "own": {
                    "posts": {
                        "display_name": "View own posts",
                        "description": "Can view own posts."
                    },
                    "pages": {
                        "display_name": "View own pages",
                        "description": "Can view own pages."
                    },
                    "projects": {
                        "display_name": "View own projects",
                        "description": "Can view own projects."
                    }
                }
            },
            "create": {
                "form_settings": {
                    "display_name": "Create form settings",
                    "description": "Can create form settings."
                },
                "users": {
                    "display_name": "Create users",
                    "description": "Can create users."
                },
                "roles": {
                    "display_name": "Create roles",
                    "description": "Can create roles."
                },
                "members": {
                    "display_name": "Create members",
                    "description": "Can create members."
                },
                "menus": {
                    "display_name": "Create menus",
                    "description": "Can create menus."
                },
                "categories": {
                    "display_name": "Create categories",
                    "description": "Can create categories."
                },
                "galleries": {
                    "display_name": "Create galleries",
                    "description": "Can create galleries."
                },
                "videos": {
                    "display_name": "Create videos",
                    "description": "Can create videos."
                },
                "events": {
                    "display_name": "Create events",
                    "description": "Can create events."
                },
                "documents": {
                    "display_name": "Create documents",
                    "description": "Can create documents."
                },
                "testimonials": {
                    "display_name": "Create testimonials",
                    "description": "Can create testimonials."
                },
                "news": {
                    "display_name": "Create news",
                    "description": "Can create news."
                },
                "metas": {
                    "display_name": "Create metas",
                    "description": "Can create metas."
                },
                "redirections": {
                    "display_name": "Create redirections",
                    "description": "Can create redirections."
                },
                "posts": {
                    "display_name": "Create posts",
                    "description": "Can create all posts."
                },
                "pages": {
                    "display_name": "Create pages",
                    "description": "Can create all pages."
                },
                "projects": {
                    "display_name": "Create projects",
                    "description": "Can create all projects."
                },
                "comments": {
                    "display_name": "Create comments",
                    "description": "Can create all comments."
                },
                "questions": {
                    "display_name": "Create questions",
                    "description": "Can create all questions."
                }
            },
            "edit": {
                "form_settings": {
                    "display_name": "Edit form settings",
                    "description": "Can edit form settings."
                },
                "users": {
                    "display_name": "Edit users",
                    "description": "Can edit users."
                },
                "roles": {
                    "display_name": "Edit roles",
                    "description": "Can edit roles."
                },
                "members": {
                    "display_name": "Edit members",
                    "description": "Can edit members."
                },
                "menus": {
                    "display_name": "Edit menus",
                    "description": "Can edit menus."
                },
                "categories": {
                    "display_name": "Edit categories",
                    "description": "Can edit categories."
                },
                "galleries": {
                    "display_name": "Edit galleries",
                    "description": "Can edit galleries."
                },
                "videos": {
                    "display_name": "Edit videos",
                    "description": "Can edit videos."
                },
                "events": {
                    "display_name": "Edit events",
                    "description": "Can edit events."
                },
                "documents": {
                    "display_name": "Edit documents",
                    "description": "Can edit documents."
                },
                "testimonials": {
                    "display_name": "Edit testimonials",
                    "description": "Can edit testimonials."
                },
                "news": {
                    "display_name": "Edit news",
                    "description": "Can edit news."
                },
                "metas": {
                    "display_name": "Edit metas",
                    "description": "Can edit metas."
                },
                "redirections": {
                    "display_name": "Edit redirections",
                    "description": "Can edit redirections."
                },
                "posts": {
                    "display_name": "Edit all posts",
                    "description": "Can edit all posts."
                },
                "pages": {
                    "display_name": "Edit all pages",
                    "description": "Can edit all pages."
                },
                "projects": {
                    "display_name": "Edit all projects",
                    "description": "Can edit all projects."
                },
                "comments": {
                    "display_name": "Edit all comments",
                    "description": "Can edit all comments."
                },
                "questions": {
                    "display_name": "Edit all questions",
                    "description": "Can edit all questions."
                },
                "own": {
                    "posts": {
                        "display_name": "Edit own posts",
                        "description": "Can edit own posts."
                    },
                    "pages": {
                        "display_name": "Edit own pages",
                        "description": "Can edit own pages."
                    },
                    "projects": {
                        "display_name": "Edit own projects",
                        "description": "Can edit own projects."
                    }
                }
            },
            "delete": {
                "form_settings": {
                    "display_name": "Delete form settings",
                    "description": "Can delete form settings."
                },
                "form_submissions": {
                    "display_name": "Delete form submissions",
                    "description": "Can delete form submissions."
                },
                "users": {
                    "display_name": "Delete users",
                    "description": "Can delete users."
                },
                "roles": {
                    "display_name": "Delete roles",
                    "description": "Can delete roles."
                },
                "members": {
                    "display_name": "Delete members",
                    "description": "Can delete members."
                },
                "menus": {
                    "display_name": "Delete menus",
                    "description": "Can delete menus."
                },
                "categories": {
                    "display_name": "Delete categories",
                    "description": "Can delete categories."
                },
                "galleries": {
                    "display_name": "Delete galleries",
                    "description": "Can delete galleries."
                },
                "videos": {
                    "display_name": "Delete videos",
                    "description": "Can delete videos."
                },
                "events": {
                    "display_name": "Delete events",
                    "description": "Can delete events."
                },
                "documents": {
                    "display_name": "Delete documents",
                    "description": "Can delete documents."
                },
                "testimonials": {
                    "display_name": "Delete testimonials",
                    "description": "Can delete testimonials."
                },
                "news": {
                    "display_name": "Delete news",
                    "description": "Can delete news."
                },
                "metas": {
                    "display_name": "Delete metas",
                    "description": "Can delete metas."
                },
                "redirections": {
                    "display_name": "Delete redirections",
                    "description": "Can delete redirections."
                },
                "posts": {
                    "display_name": "Delete all posts",
                    "description": "Can delete all posts."
                },
                "pages": {
                    "display_name": "Delete all pages",
                    "description": "Can delete all pages."
                },
                "projects": {
                    "display_name": "Delete all projects",
                    "description": "Can delete all projects."
                },
                "comments": {
                    "display_name": "Delete all comments",
                    "description": "Can delete all comments."
                },
                "questions": {
                    "display_name": "Delete all questions",
                    "description": "Can delete all questions."
                },
                "own": {
                    "posts": {
                        "display_name": "Delete own posts",
                        "description": "Can delete own posts."
                    },
                    "pages": {
                        "display_name": "Delete own pages",
                        "description": "Can delete own pages."
                    },
                    "projects": {
                        "display_name": "Delete own projects",
                        "description": "Can delete own projects."
                    }
                }
            },
            "publish": {
                "posts": {
                    "display_name": "Publish posts",
                    "description": "Can manage posts publication."
                },
                "pages": {
                    "display_name": "Publish pages",
                    "description": "Can manage pages publication."
                },
                "projects": {
                    "display_name": "Publish projects",
                    "description": "Can manage projects publication."
                }
            },
            "impersonate": {
                "display_name": "Impersonate user",
                "description": "Can take ownership of others user identities. Useful for tests."
            }
        },
        "routes": {
            "home": "home",
            "about": "about",
            "contact": "contact",
            "contact-sent": "contact-sent",
            "legal-mentions": "legal-mentions",
            "redactors": "blog/redactors/{user}",
            "project-owner": "project/project-owner/{user}"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "Firstname",
                "last_name": "Lastname",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "video": "Video",
                "document": "Document",
                "report": "Report",
                "default_image": "Default Image",
                "project_image": "Project Image",
                "project_images": "Project Images",
                "project_videos": "Project Videos",
                "project_documents": "Project Documents",
                "project_reports": "Project Reports",
                "type": "Type",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Published at",
                "unpublished_at": "Unpublish at",
                "beginning_date": "Beginning date",
                "finishing_date": "Finishing date",
                "metable_type": "Entity",
                "team_name": "Team Name",
                "team_description": "Team Description",
                "team_lead": "Chief Inspector",
                "team_members": "Team members",
                "meeting_lead": "Chair by",
                "meeting_members": "Participant members",
                "link": "Link",
                "venue": "Venue",
                "organised_by": "Organised by",
                "chair_by": "Chair by",
                "designation": "Designation",
                "superior": "Superior",
                "avatar": "Avatar",
                "commenter": "Commenter",
                "commentable_type": "Model",
                "parent_category": "Parent Category",
                "category": "Category",
                "parent_menu": "Parent Menu",
                "menu": "Menu",
                "organizer": "Organizer",
                "contacts": "Contacts",
                "access_type": "Access type",
                "start_date": "Start date",
                "end_date": "End date",
                "event_image": "Event image",
                "no_of_participants": "No of participants",
                "fees": "Fees",
                "author": "Author",
                "document_image": "Document image",
                "document_documents": "Document documents",
                "rating": "Rating",
                "bio": "Biography",
                "organization": "Organization",
                "batch": "Batch",
                "working_sector": "Working Sector"
            }
        }
    }
}
